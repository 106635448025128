<template>
    <v-dialog v-model="openMode" max-width="600px">
        <v-card class="gradient-button-background">
            <div style="text-align: center; padding: 5px;">
                <span class="text-h5">{{ formTitle }}</span>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field hide-details v-model="itemEdited.name" outlined dense
                                    :rules="[v => !!v || 'שדה חובה']" required label="שם חבילה"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field hide-details hide-spin-buttons type="number" v-model="itemEdited.price"
                                    :rules="[v => !!v || 'שדה חובה']" required outlined dense label="מחיר"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field hide-details v-model="itemEdited.channels" outlined dense
                                    :rules="[v => !!v || 'שדה חובה']" required label="ערוצים"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field hide-details hide-spin-buttons type="number" v-model="itemEdited.contacts"
                                    :rules="[v => !!v || 'שדה חובה']" required outlined dense
                                    label="אנשי קשר"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="padding-top: 0px !important;">
                            <v-col cols="12" sm="6" style="padding-top: 0px !important;">
                                <v-text-field hide-details hide-spin-buttons type="number" v-model="itemEdited.storage"
                                    :rules="[v => !!v || 'שדה חובה']" required outlined dense
                                    label="גי'גה אחסון"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="padding-top: 0px !important;">
                            <v-col cols="12" sm="6" style="padding-top: 0px !important;">
                                <div>
                                    <v-checkbox v-model="itemEdited.show_to_user" hide-details
                                        :label="`הצג למשתמשים`"></v-checkbox>
                                </div>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div style="display: flex; justify-content: end !important; padding-bottom: 2px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin: 10px; font-size: medium; color: black; "
                    @click="formTitle === 'ערוך חבילה' ? saveEditedPlan() : saveNewPlan()">
                    שמור
                </v-btn>
                <v-btn color="#adacaa2c" class="gradient-button-background" @click="openMode = false"
                    style="font-size: medium; margin: 10px;">
                    ביטול
                </v-btn>
            </div>

        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        planToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            name: "",
            channels: "",
            storage: "",
            contacts: "",
            price: "",
            show_to_user: "",
        },
    }),
    methods: {
        async saveNewPlan() {

            if (this.$refs.form.validate()) {

                const ivrJSON = JSON.stringify({ plan: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin_api/create_plan";
                    const res = await fetch(api, ApiServices.requestOptions("POST", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("planCreated", this.itemEdited, "החבילה נוצרה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved new plan: " + error, "red");
                }
            }
        },
        async saveEditedPlan() {
            if (this.$refs.form.validate()) {

                const ivrJSON = JSON.stringify({ plan: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin_api/edit_plan";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("planEdited", this.itemEdited, "החבילה עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved edited plan: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך חבילה") {
            this.itemEdited = JSON.parse(JSON.stringify(this.planToEdit));
        }
    },
}
</script>
<style scoped>
.negative-elevation {
    /* Example of a custom style to mimic negative elevation */
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Other styles can be added here as needed */
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}
</style>
  