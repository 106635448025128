<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card
            style="padding-left: 30px; padding-right: 30px; background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <div style="text-align: center; padding: 5px;">
                <span class="text-h5">הרשאות</span>
            </div>

            <v-row no-gutters style="margin-bottom: 40px; border: 1px solid rgba(117, 116, 116, 0.452);">
                <v-col cols="12" sm="12">
                    <v-col cols="12" sm="12" style="padding-top: 0px;">
                        <v-checkbox v-model="permissionsToEdit.confRoom" hide-details :label="`חדר ועידה`"></v-checkbox>
                    </v-col>
                </v-col>
                <v-col cols="12" sm="12">
                    <v-col cols="12" sm="12" style="padding-top: 0px;">
                        <v-checkbox v-model="permissionsToEdit.playSubscribersNumbers" hide-details :label="`השמעת פרטי החברים בקבוצה`"></v-checkbox>
                    </v-col>
                </v-col>
            </v-row>

            <div style="display: flex; justify-content: start !important; padding-top: 20px; padding-bottom: 20px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px;" @click="saveChanges()">שמור שינויים</v-btn>
                <v-btn :color="'gradient-button-background'" @click="closeDelete">סגור</v-btn>
            </div>

            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>

        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        ivr: Object,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        newLine: "",
        permissionsToEdit:{},
        sendCall: false,
    }),

    methods: {
        async saveChanges() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const permissionsJSON = JSON.stringify({ permissionsObj: this.permissionsToEdit, lineNumber: this.ivr.line_number});

                const api = process.env.VUE_APP_BASE_URL + "/admin_api/change_ivr_permissions";
                const res = await fetch(api, ApiServices.requestOptions("POST", permissionsJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בשמירת ההרשאות ", "red");
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.progressShow = false;
                    this.$emit("ivrPermissionsChanged", this.permissionsToEdit)
                    this.showSnackBar("השינויים נשמרו בהצלחה!", "green");
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בשמירת השינויים : " + error, "red");
            }
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.permissionsToEdit = { ...this.ivr.exten_permissions};
        console.log(this.permissionsToEdit);
    },
}
</script>
<style scoped>
.negative-elevation {
    /* Example of a custom style to mimic negative elevation */
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Other styles can be added here as needed */
}


.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}
</style>
    