<template >
    <v-container>

        <div style="margin-bottom: 20px; margin-right: 20px;">
            <v-row>
                <v-col cols="6" sm="1" style="padding-left: 0px; padding-top: 15px;">
                    <span style="font-size: large; font-weight: 600;">הרשאות הקלטה:</span>
                </v-col>
                <v-col cols="6" sm="2" style="padding-right: 0px; padding-top: 0px;">
                    <v-radio-group v-model="radioGroup.all_can_record">
                        <v-radio v-for="n in groupType" color="#0d2c6d" :key="n" :label="`${n}`" :value="n"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>

        <div style="background-color: #eee; border: 3px solid white;">
            <div style="margin-bottom: 20px; margin-top: 10px;  margin-right: 20px;">
                <v-row>
                    <v-col cols="6" sm="1" style="padding-left: 0px; padding-top: 15px;">
                        <span style="font-size: large; font-weight: 600;">שפה:</span>
                    </v-col>
                    <v-col cols="6" sm="2" style="padding-right: 0px; padding-top: 0px;">
                        <v-radio-group v-model="radioGroup.language">
                            <v-radio v-for="n in languageList" color="#0d2c6d" :key="n" :label="`${n}`"
                                :value="n"></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div style="margin-bottom: 20px; margin-top: 10px;  margin-right: 20px;">
            <v-row>
                <v-col cols="6" sm="1" style="padding-left: 0px; padding-top: 15px;">
                    <span style="font-size: large; font-weight: 600;">הוספת חברים לקבוצה ע"י כל משתמש:</span>
                </v-col>
                <v-col cols="6" sm="2" style="padding-right: 0px; padding-top: 0px;">
                    <v-radio-group v-model="radioGroup.all_can_add_subscribers">
                        <v-radio v-for="n in addSubscribePermission" color="#0d2c6d" :key="n" :label="`${n}`"
                            :value="n"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>

        <div style="background-color: #eee; border: 3px solid white;">
            <div style="margin-bottom: 20px; margin-top: 10px;  margin-right: 20px;">
                <v-row>
                    <v-col cols="6" sm="1" style="padding-left: 0px; padding-top: 15px;">
                        <span style="font-size: large; font-weight: 600;">סיסמה למערכת:</span>
                    </v-col>
                    <v-col cols="6" sm="2" style="padding-right: 0px; padding-top: 0px;">
                        <v-radio-group v-model="radioGroup.system_password">
                            <v-radio v-for="n in systemPasswordOptions" color="#0d2c6d" :label="n" :value="n"></v-radio>
                        </v-radio-group>
                        <div>
                            <v-text-field v-if="radioGroup.system_password === 'לא פעיל'" disabled background-color="white"
                                outlined dense></v-text-field>
                            <v-text-field v-else background-color="white" maxLength="4" v-model="details.system_password"
                                outlined dense label="הזן סיסמת כניסה"></v-text-field>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div style="margin-bottom: 80px; margin-top: 10px;  margin-right: 20px;">
            <v-row>
                <v-col cols="6" sm="1" style="padding-left: 7px; padding-top: 15px;">
                    <span style="font-size: large; font-weight: 600;">מחיקת הקלטות לאחר 30 יום:</span>
                </v-col>
                <v-col cols="6" sm="2" style="padding-right: 0px; padding-top: 0px;">
                    <v-radio-group v-model="radioGroup.delete_after_thirty_days">
                        <v-radio v-for="n in deleteAfterThirtyDays" color="#0d2c6d" :key="n.hebrewName" :label="`${n.hebrewName}`"
                            :value="n.value"></v-radio>
                    </v-radio-group>
                </v-col>
            </v-row>
        </div>


        <v-row v-if="progressShow" style="margin-top: 20px;">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
            </v-col>
        </v-row>

        <v-row style="margin-bottom: 70px; margin-top: 30px; margin-right: 20px; ">
            <v-col cols="12">
                <v-btn class="gradient-yellow-button-background" style="margin-left: 15px; font-size: medium;"
                    @click="saveChanges()">
                    שמור שינויים
                </v-btn>
            </v-col>

        </v-row>


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    components: {
        SnackBar,

    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        radioGroup: {
            all_can_record: null,
            language: null,
            all_can_add_subscribers: null,
            delete_after_thirty_days : null,
            system_password: null,
        },

        progressShow: false,
        groupType: ["רגילה", "מנהלים"],
        languageList: ["עברית", "אנגלית", "אידיש ישראלי", "אידיש אמריקאי"],
        addSubscribePermission: ["כן", "לא"],
        deleteAfterThirtyDays: [{hebrewName: "כן", value : true}, {hebrewName: "לא", value : false}],
        systemPasswordOptions: ["לא פעיל", "אפשר"],
        details: {},

    }),
    methods: {

        async getSettingsDetails() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_settings_details`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.details = jsonObject;
                    console.log(this.details);
                    this.setSystemSettings();
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        setSystemSettings() {
            if (this.details.all_can_record) {
                this.radioGroup.all_can_record = "רגילה"
            } else {
                this.radioGroup.all_can_record = "מנהלים"
            }

            if (this.details.language === 'he') {
                this.radioGroup.language = "עברית"
            } else if (this.details.language === 'en') {
                this.radioGroup.language = "אנגלית"
            } else if (this.details.language === 'yi_il') {
                this.radioGroup.language = "אידיש ישראלי"
            } else {
                this.radioGroup.language = "אידיש אמריקאי"
            }

            if (this.details.all_can_add_subscribers) {
                this.radioGroup.all_can_add_subscribers = "כן"
            } else {
                this.radioGroup.all_can_add_subscribers = "לא"
            }

            if (this.details.system_password) {
                this.radioGroup.system_password = "אפשר"
            } else {
                this.radioGroup.system_password = "לא פעיל"
            }

            if (this.details.delete_settings.delete_after_thirty_days) {
                this.radioGroup.delete_after_thirty_days = true
            } else {
                this.radioGroup.delete_after_thirty_days = false
            }
        },
        async saveChanges() {

            try {
                if (this.details.system_password) {
                    const numberRegex = /^\d+$/; // Only numeric characters
                    const isValidFormat = numberRegex.test(this.details.system_password);
                    if (!isValidFormat) {
                        return this.showSnackBar("הסיסמה כוללת ספרות בלבד", "red");
                    }
                    if (this.details.system_password.length != 4) {
                        return this.showSnackBar("הסיסמה חייבת לכלול 4 ספרות", "red");
                    }
                }

                const updatedObj = {
                    all_can_record: this.radioGroup.all_can_record === "רגילה" ? true : false,
                    language: this.radioGroup.language === "עברית" ? "he" : this.radioGroup.language === "אנגלית" ? "en" : this.radioGroup.language === "אידיש ישראלי" ? "yi_il" : "yi-us",
                    all_can_add_subscribers: this.radioGroup.all_can_add_subscribers === "כן" ? true : false,
                    system_password: this.radioGroup.system_password === "אפשר" ? this.details.system_password : "",
                    delete_settings: {delete_after_thirty_days: this.radioGroup.delete_after_thirty_days},
                }

                this.progressShow = true;
                const detailsJSON = JSON.stringify({updatedObj});
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/save_changes`;
                const res = await fetch(api, ApiServices.requestOptions("PUT", detailsJSON, token));
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בשמירת השינויים", "red");
                } else if (res.status === 200) {
                    this.showSnackBar("השינויים עודכנו בהצלחה!", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error save changes: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },

    },
    mounted() {
        this.getSettingsDetails();
    },
}
</script>
<style>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */

.v-radio label {
    /* Your custom styles here */
    font-weight: bold;
}

div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    