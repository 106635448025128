<template>
    <v-dialog v-model="openMode" max-width="900px" persistent>
        <v-card class="gradient-button-background">
            <div style="text-align: center; padding: 5px;">
                <span class="text-h5">{{ formTitle }}</span>
            </div>
            <v-card-text>
                <v-container>
                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px !important;">
                                        <div style="padding-bottom: 10px !important;">
                                            <span class="text-h6" style="font-weight: 600; color: black;">פרטי
                                                התחברות:</span>
                                        </div>
                                        <v-text-field v-if="formTitle === 'ערוך משתמש:'" v-model="itemEdited.line_number"
                                            disabled hide-details outlined dense type="number" hide-spin-buttons
                                            label="שם משתמש" class="negative-elevation" :rules="[v => !!v || 'שדה חובה']"
                                            required></v-text-field>
                                        <v-text-field v-else v-model="itemEdited.line_number" hide-details outlined dense
                                            type="number" hide-spin-buttons label="שם משתמש" class="negative-elevation"
                                            :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px !important;">
                                        <v-text-field v-if="formTitle === 'ערוך משתמש:'" v-model="passObj.password"
                                            hide-spin-buttons hide-details outlined dense label="סיסמה"
                                            class="negative-elevation"></v-text-field>
                                        <v-text-field v-else v-model="itemEdited.password.hash" hide-details outlined dense
                                            hide-spin-buttons label="סיסמה" class="negative-elevation"
                                            :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px !important;">
                                        <v-text-field v-if="formTitle === 'ערוך משתמש:'" v-model="passObj.repeat_password"
                                            hide-spin-buttons hide-details outlined dense class="negative-elevation"
                                            label="אישור סיסמה"></v-text-field>
                                        <v-text-field v-else v-model="itemEdited.repeat_password" hide-details outlined
                                            hide-spin-buttons dense class="negative-elevation" label="אישור סיסמה"
                                            :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col cols="12" sm="1" style="padding-bottom: 0px !important;">
                            </v-col>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px !important;">
                                        <div style="padding-bottom: 10px !important;">
                                            <span class="text-h6" style="font-weight: 600; color: black;">ניהול
                                                בטלפון</span>
                                        </div>
                                        <v-text-field v-model="itemEdited.phone_password" hide-details outlined dense
                                            type="number" hide-spin-buttons class="negative-elevation" label="סיסמה לניהול"
                                            :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col cols="12" style="padding-bottom: 0px !important;">
                                        <v-text-field v-model="itemEdited.owner_details.phone" hide-details disabled
                                            outlined dense label="כניסה באמצעות זיהוי מספר"></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row style="border-top: 1px solid rgba(117, 116, 116, 0.452); margin-top: 60px;">
                            <v-col cols="12" style="padding-bottom: 0px !important;">
                                <span class="text-h6" style="font-weight: 600; color: black;">פרטים אישיים</span>
                            </v-col>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.owner_details.name" hide-details outlined dense
                                    label="שם איש קשר" class="negative-elevation" :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.owner_details.business_name" hide-details outlined dense :rules="[v => !!v || 'שדה חובה']" required
                                    class="negative-elevation" label="שם המוסד/ארגון"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" style="padding-bottom: 0px !important;">
                                <v-select background-color="white" :items="status" v-model="itemEdited.status" outlined
                                    dense label="מצב" :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.email" hide-details outlined dense
                                    class="negative-elevation" label="דואר אלקטרוני"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.owner_details.phone" hide-details outlined dense
                                    label="טלפון" type="number" hide-spin-buttons class="negative-elevation"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="2" style="padding-bottom: 0px !important;">
                                <v-select background-color="white" :items="roles" v-model="itemEdited.role" outlined dense
                                    label="סוג"></v-select>
                            </v-col>

                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.owner_details.business_id" hide-details outlined dense :rules="[v => !!v || 'שדה חובה']" required
                                    class="negative-elevation" label="ת.ז/ח.פ"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.owner_details.address" hide-details outlined dense
                                    label="כתובת" hide-spin-buttons class="negative-elevation"></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row style="border-top: 1px solid rgba(117, 116, 116, 0.452); margin-top: 30px; ">
                            <v-col cols="12" style="padding-bottom: 0px !important;">
                                <span class="text-h6" style="font-weight: 600; color: black;">פרטי מנוי</span>
                            </v-col>
                            <v-col cols="12" sm="4" style="padding-bottom: 0px !important;">
                                <v-select :items="plans" :item-text="'name'" :item-value="'name'" v-model="selectedPlanName"
                                    outlined dense label="חבילה">
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="4" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.plan.trying_finish_date" outlined
                                    label="תקופת ניסיון עד לתאריך" dense hide-details type="datetime-local"></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row style="border-top: 1px solid rgba(117, 116, 116, 0.452); margin-top: 30px; ">
                            <v-col cols="12" style="padding-bottom: 0px !important;">
                                <span class="text-h6" style="font-weight: 600; color: black;">הערות</span>
                            </v-col>
                            <v-col cols="12" style="padding-bottom: 0px !important;">
                                <v-textarea v-model="itemEdited.notes" rows="1" outlined dense>
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div style="display: flex; justify-content: end !important; padding-bottom: 2px;">
                <v-btn
                    :color="formTitle === 'ערוך משתמש:' ? 'gradient-button-background' : 'gradient-yellow-button-background'"
                    style="margin: 10px; font-size: medium; color: black; "
                    @click="formTitle === 'ערוך משתמש:' ? saveEditedIvr() : saveNewIVR()">
                    שמור
                </v-btn>
                <v-btn color="#adacaa2c" class="gradient-button-background" @click="openMode = false"
                    style="font-size: medium; margin: 10px;">
                    ביטול
                </v-btn>
                <v-btn v-if="formTitle === 'ערוך משתמש:'" :color="'gradient-yellow-button-background'"
                    @click="openLinesDialog()" style="font-size: medium; margin: 10px;">
                    הוספת קווים למשתמש
                </v-btn>
                <v-btn v-if="formTitle === 'ערוך משתמש:'" :color="'gradient-yellow-button-background'"
                    @click="openPermissionsDialog()" style="font-size: medium; margin: 10px;">
                    הרשאות
                </v-btn>
            </div>

        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <AddLinesDialog v-model="addLinesDialog" v-if="addLinesDialog" :ivr="ivrToAddLines"></AddLinesDialog>

        <PermissionsDialog v-model="permissionsDialog" v-if="permissionsDialog" :ivr="ivrToChangePermission"
            @ivrPermissionsChanged="ivrPermissionsChanged"></PermissionsDialog>


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'
import AddLinesDialog from '@/components/admin/dialogs/AddLinesDialog'
import PermissionsDialog from '@/components/admin/dialogs/PermissionsDialog'
import SnackBar from '@/components/widgets/snackBar.vue'
import Util from '@/util/utils.js'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
        AddLinesDialog,
        PermissionsDialog
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        ivrToAddLines: null,
        addLinesDialog: false,
        ivrToChangePermission: null,
        permissionsDialog: false,
        itemEdited: {
            owner_details: {
                phone: "",
                business_name: "",
                name: "",
                business_id: "",
                address: "",
            },
            plan: {
                plan_name: "",
                has_trying_period: false,
            },
            notes: "",
            password: {
                hash: "",
                salt: "",
            },
        },
        passObj: {
            password: "",
            repeat_password: "",
        },
        status: ["פעיל", "מושבת"],
        // roles: ["user", "agent", "admin"],
        roles: ["user", "agent"],
        plans: [],
        // plans: ["מסלול חינם", "מסלול פרימיום"]
    }),
    methods: {
        async saveNewIVR() {

            if (this.$refs.form.validate()) {

                if (this.itemEdited.password.hash) {
                    if (this.itemEdited.password.hash !== this.itemEdited.repeat_password) {
                        return this.showSnackBar("נא לאמת את הסיסמה החדשה", "red");
                    }

                    if (this.itemEdited.password.hash.length < 9 || this.itemEdited.password.hash.length > 20) {
                        return this.showSnackBar("הסיסמה חייבת לכלול לפחות 9 תווים ולא יותר מ 20", "red");
                    }
                }
                const ivrJSON = JSON.stringify({ ivr: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin_api/create_ivr";
                    const res = await fetch(api, ApiServices.requestOptions("POST", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("ivrCreated", this.itemEdited, "המערכת נוצרה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved new contact: " + error, "red");
                }
            }
        },
        async saveEditedIvr() {
            if (this.$refs.form.validate()) {

                if (this.passObj.password) {
                    if (this.passObj.password !== this.passObj.repeat_password) {
                        return this.showSnackBar("נא לאמת את הסיסמה החדשה", "red");
                    }

                    if (this.passObj.password.length < 9 || this.passObj.password.length > 20) {
                        return this.showSnackBar("הסיסמה חייבת לכלול לפחות 9 תווים ולא יותר מ 20", "red");
                    }
                }

                const ivrJSON = JSON.stringify({ ivr: this.itemEdited, passObj: this.passObj });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/admin_api/edit_ivr";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", ivrJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.showSnackBar("Error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("ivrEdited", this.itemEdited, "המערכת עודכנה בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved list: " + error, "red");

                }
            }
        },
        async getPlans() {
            try {
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + "/admin_api/get_plans";
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status === 400) {
                    this.showSnackBar("שגיאה בקבלת החבילות", "red");
                } else if (res.status === 200) {
                    this.plans = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בקבלת החבילות: " + error, "red");
            }
        },
        openLinesDialog() {
            this.ivrToAddLines = this.itemEdited;
            this.addLinesDialog = true
        },
        openPermissionsDialog() {
            this.ivrToChangePermission = this.itemEdited;
            this.permissionsDialog = true
        },
        ivrPermissionsChanged(permissionsObj) {
            this.itemEdited.exten_permissions = permissionsObj;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
        selectedPlanName: {
            get() {
                return this.itemEdited.plan.plan_name;
            },
            set(value) {
                const selectedPlan = this.plans.find(plan => plan.name === value);
                if (selectedPlan) {
                    this.itemEdited.plan.plan_id = selectedPlan._id;
                }
            }
        }
    },
    mounted() {
        if (this.formTitle === "ערוך משתמש:") {
            this.itemEdited = JSON.parse(JSON.stringify(this.itemToEdit));
            this.itemEdited.plan.trying_finish_date = Util.getFormatDate(this.itemEdited.plan.trying_finish_date);
            if (this.itemEdited.status == true) {
                this.itemEdited.status = "פעיל"
            } else {
                this.itemEdited.status = "מושבת"
            }
            this.ivrToAddLines = JSON.parse(JSON.stringify(this.itemToEdit));
            if (this.itemEdited.role === "admin") {
                this.roles = ["admin"]
            }
        }
        this.getPlans();
    },
}
</script>
<style scoped>
.negative-elevation {
    /* Example of a custom style to mimic negative elevation */
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Other styles can be added here as needed */
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}
</style>
  