<template >
    <div>

        <div style=" width: 100%; margin-bottom: 20px; border: 1px solid #dad7d7; display: none;" id="allert-upload">
            <span style="color: green; font-weight: bold; font-size: medium;">
            </span>
        </div>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer"
                style="display: flex; justify-content: space-around; padding-left: 10px !important; padding-right: 10px !important;">

                <v-row>
                <v-col cols="12" sm="5" md="4" class="box" v-for="(item, index) in items">
                    <div class="textBox">{{ item.name }}</div>
                    <div class="eee">
                        <v-select v-if="item.fileName === 'start_message.wav'" :items="types" v-model="item.isPressable" dense
                            style="width: 80px; "></v-select>
                    </div>
                    <div style="display: flex; justify-content: center; margin-top: 8px;">
                        <div class="attachContainer gradient-button-background">
                            <div class="attachIcon">
                                <img src="@/assets/upload/icon_attach.svg">
                            </div>
                            <label :for="'fileInput' + index"
                                style="width: 80%; height: 100%;  display: flex; align-items: center; justify-content: center; cursor: pointer;">
                                בחירת קובץ
                            </label>
                            <v-file-input :id="'fileInput' + index" prepend-icon="" v-model="item.fileInput"
                                @change="handleFileInputChange(item)" style="display: none;"></v-file-input>
                        </div>
                        <audio id="myAudio1"></audio>

                        <div v-if="item.name === 'הודעה זמנית' && item.fileInput" style="margin: 20px !important;">
                            <DatePicker v-model="datePicker" />
                        </div>

                    </div>
                    <v-progress-linear v-if="item.progressUpload" indeterminate color="yellow darken-2" height="15"
                        style="margin-top: 25px; margin-right: 5px; margin-left: 5px;"></v-progress-linear>

                    <div v-if="item.progressLoading">
                        <v-progress-circular indeterminate color="primary" height="15"
                            style="margin-top: 25px; margin-right: 5px; margin-left: 5px;"></v-progress-circular>
                    </div>

                    <div id="file-show1" v-if="item.fileInput && !item.progressUpload"
                        style="margin-top: 25px; margin-right: 5px; margin-left: 5px; background-color: #dad7d7; height: 30px; box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); display: flex;">
                        <div class="deleteIcon" @click="deleteFile(item)" v-if="item.name !== 'תפריט ראשי'">
                            <img src="@/assets/upload/icon_delete.svg">
                        </div>
                        <div style="width: 90%; display: flex; align-items: center; direction: ltr; padding-left: 5px;">
                            <template v-if="isFile(item.fileInput)">{{ item.fileInput.name }}</template>
                            <template v-else>{{ item.fileName }}
                            </template>
                        </div>
                    </div>

                    <div id="buttons-enabled1" v-if="item.fileInput"
                        style="width: 100%; display: flex; justify-content: center; align-self: flex-end; margin-top: auto; margin-bottom: 10px;">
                        <img src="@/assets/upload/icon_volume-up.svg" :id="'volume-img' + index" style="margin: 10px;"
                            class="audioButtons" @click="volume(item, index)">
                        <img src="@/assets/upload/icon_fast-forward.svg" style="margin: 10px;" class="audioButtons"
                            @click="fast(item)">
                        <img src="@/assets/upload/icon_fast-rewind.svg" style="margin: 10px;" class="audioButtons"
                            @click="slow(item)">
                        <img src="@/assets/upload/icon_stop.svg" style="margin: 10px;" class="audioButtons"
                            @click="stop(item)">
                        <img src="@/assets/upload/icon_pause.svg" style="margin: 10px;" class="audioButtons"
                            @click="pause(item)">
                        <img src="@/assets/upload/icon_play.svg" style="margin: 10px;" class="audioButtons"
                            @click="play(item)">
                        <img src="@/assets/upload/icon_upload-file.svg" style="margin: 10px;" class="audioButtons"
                            title="העלה קובץ זה לשרת" @click="uploadFile(item)">
                        <a :href="item.fileInput" :download="item.fileName">
                            <img src="@/assets/upload/icon_download_file.svg" style="margin: 10px;" class="audioButtons"
                                title="הורד קובץ">
                        </a>
                        <!-- <div v-if="item.fileInput" style="margin-top: 13px !important;"
                            @click="saveLimitDateAndPriority(item)">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_save.svg" v-on="on"
                                        style="margin-bottom: 5px; cursor: pointer;" class="audioButtons">
                                </template>
                                <span>שמור שינויים</span>
                            </v-tooltip>
                        </div> -->

                    </div>

                    <div id="buttons-disabled1" v-else
                        style="width: 100%; display: flex; justify-content: center; align-self: flex-end; margin-top: auto; margin-bottom: 10px;">
                        <img src="@/assets/upload/icon_volume-up disabled.svg" id="imgg" style="margin: 10px;"
                            class="audioButtons">
                        <img src="@/assets/upload/icon_fast-forward disabled.svg" style="margin: 10px;"
                            class="audioButtons">
                        <img src="@/assets/upload/icon_fast-rewind disabled.svg" style="margin: 10px;" class="audioButtons">
                        <img src="@/assets/upload/icon_stop disabled.svg" style="margin: 10px;" class="audioButtons">
                        <img src="@/assets/upload/icon_pause disabled.svg" style="margin: 10px;" class="audioButtons">
                        <img src="@/assets/upload/icon_play disabled.svg" style="margin: 10px;" class="audioButtons">
                    </div>

                </v-col>
            </v-row>
            </div>

        </div>

        <div>
            <v-btn style="margin-right: 100px;" class="gradient-button-background" @click="saveLimitDateAndPriority()">שמור
                שינויים</v-btn>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import axios from 'axios'
import DatePicker from "@/components/widgets/DatePicker";

export default {
    components: {
        SnackBar,
        DatePicker,
    },
    data: () => ({
        userName: "",
        lineNumber: "",
        snackbar: false,
        datePicker: "",
        // datePicker: "2023-11-15 14:30",
        snackbarColorBt: "green",
        snacbarText: "",
        types: ["עקיף", "בלתי עקיף"],
        items: [
            // {
            //     name: "תפריט ראשי",
            //     fileInput: "",
            //     audioElement: "",
            //     fileName: "menu.wav",
            //     progressUpload: false,
            //     progressLoading: false,
            // },
            {
                name: "הודעת פתיחה",
                fileInput: "",
                audioElement: "",
                fileName: "start_message.wav",
                isPressable: "עקיף",
                progressUpload: false,
                progressLoading: false,
            },
            {
                name: "הודעה זמנית",
                fileInput: "",
                audioElement: "",
                fileName: "temporary_message.wav",
                isPressable: "עקיף",
                progressUpload: false,
                progressLoading: false
            },
        ]


    }),
    methods: {

        async getRecords() {
            try {
                let token = localStorage.getItem("token");
                const links = [
                    // process.env.VUE_APP_BASE_URL + `/users_records/${this.lineNumer}/menu/menu.wav`,
                    process.env.VUE_APP_BASE_URL + `/users_records/${this.lineNumer}/menu/start_message.wav`,
                    process.env.VUE_APP_BASE_URL + `/users_records/${this.lineNumer}/menu/temporary_message.wav`
                ]
                for (let i = 0; i < 2; i++) {
                    this.items[i].progressLoading = true;
                    const res = await fetch(links[i], ApiServices.requestOptions("GET", "", token));
                    const jsonObject = await res.blob();
                    if (res.status === 200) {
                        const url = URL.createObjectURL(jsonObject);
                        this.items[i].fileInput = url;
                        this.items[i].audioElement = new Audio(url);
                    }
                    this.items[i].progressLoading = false;
                }
                console.log(this.items);
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        async getLimitDateAndUnpress() {
            try {
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/records/get_limit_date_record_and_unpressed`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showAllertMessage("שגיאה  בקבלת הנתונים ", "rgb(150, 19, 19)");
                } else if (res.status === 200) {
                    if (jsonObject.menu_messages.temporary_message_limit) {
                        const dateLimit = new Date(jsonObject.menu_messages.temporary_message_limit)
                        // Extract date components
                        const year = dateLimit.getFullYear();
                        const month = String(dateLimit.getMonth() + 1).padStart(2, '0');
                        const day = String(dateLimit.getDate()).padStart(2, '0');
                        const hour = String(dateLimit.getHours()).padStart(2, '0');
                        const minute = String(dateLimit.getMinutes()).padStart(2, '0');

                        // Format as "YYYY-MM-DD hh:mm"
                        this.datePicker = `${year}-${month}-${day} ${hour}:${minute}`;
                    }
                    for (let i = 0; i < this.items.length; i++) {
                        if (this.items[i].fileName === "start_message.wav") {
                            let isPressable = jsonObject.menu_messages.start_message;
                            if (isPressable === "pressable") {
                                this.items[i].isPressable = "עקיף";
                            } else {
                                this.items[i].isPressable = "בלתי עקיף";
                            }
                        } else if (this.items[i].fileName === "temporary_message.wav") {
                            let isPressable = jsonObject.menu_messages.temporary_message;
                            if (isPressable === "pressable") {
                                this.items[i].isPressable = "עקיף";
                            } else {
                                this.items[i].isPressable = "בלתי עקיף";
                            }
                        }
                    }

                }
            } catch (error) {
                this.showSnackBar("Error get details: " + error, "red");
            }
        },
        async uploadFile(item) {
            console.log(item.name);
            try {
                if (item.fileInput instanceof File) {
                    let type;
                    if (item.name === "הודעת פתיחה") {
                        type = "start_message"
                    }else if (item.name === "הודעה זמנית") {
                        type = "temporary_message"
                    } else {
                        return;
                    }

                    item.progressUpload = true;
                    let token = localStorage.getItem("token");
                    let api = process.env.VUE_APP_BASE_URL + `/records/change_menu_record`;

                    const formData = new FormData();
                    formData.append("record", item.fileInput);
                    formData.append("type", type);
                    const response = await axios.post(api, formData, {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.status >= 400) {
                        item.progressUpload = false;
                        this.showAllertMessage("שגיאה: לא ניתן להעלות את קובץ " + item.fileInput.name, "rgb(150, 19, 19)");
                        showAllert.style.display = 'block';
                        spanElement.textContent = "שגיאה: לא ניתן להעלות את קובץ " + uploadedFileName;
                        spanElement.style.color = "rgb(150, 19, 19)"; // Apply the CSS class for text color
                    } else if (response.status === 200) {
                        item.progressUpload = false;
                        this.showAllertMessage("קובץ: " + item.fileInput.name + " הועלה בהצלחה לתיקיה הראשית בתור קובץ " + item.name, 'green');
                    }

                }
            } catch (error) {
                item.progressUpload = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        showAllertMessage(text, color) {
            const showAllert = document.getElementById('allert-upload');
            const spanElement = showAllert.querySelector('span');
            showAllert.style.display = 'block';
            spanElement.textContent = text;
            // spanElement.classList.add(colorClass); // Apply the CSS class for text color
            spanElement.style.color = color;
            // Schedule the allert upload div to disappear after 4 seconds
            setTimeout(function () {
                showAllert.style.display = 'none';
            }, 3000);

        },
        handleFileInputChange(item) {

            if (item.fileInput) {
                //   // Create a URL for the selected audio file
                item.audioElement = new Audio(URL.createObjectURL(item.fileInput));
            } else {
                item.audioElement = "";
            }

        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        volume(item, index) {
            const img = document.getElementById('volume-img' + index);
            if (item.audioElement.volume > 0) {
                item.audioElement.volume = 0.0;
                img.src = require('@/assets/upload/icon_volume-off.svg')
            }
            else {
                item.audioElement.volume = 0.6;
                img.src = require('@/assets/upload/icon_volume-up.svg')
            }
        },
        fast(item) {
            if (item.audioElement.playbackRate < 1.8) {
                item.audioElement.playbackRate += 0.4;
                item.audioElement.play();
            }
        },
        slow(item) {
            if (item.audioElement.playbackRate > 0.2) {
                item.audioElement.playbackRate -= 0.4;
                item.audioElement.play();
            }
        },
        play(item) {
            // console.log(this.datePicker);
            item.audioElement.play();
            item.audioElement.addEventListener('ended', () => {
                this.stop(item);
            });
            item.audioElement.addEventListener('error', (event) => {
                this.stop(item);
                this.showSnackBar('שגיאה: קובץ שמע לא תקין', "red");
            });
        },
        stop(item) {
            if (item.audioElement) {
                item.audioElement.pause();
                item.audioElement.currentTime = 0;
            }
        },
        pause(item) {
            if (item.audioElement) {
                item.audioElement.pause();
            }
        },
        async deleteFile(item) {
            // Clear the file input
            item.fileInput = '';
            item.audioElement = '';

            try {
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ fileName: item.fileName });
                let api = process.env.VUE_APP_BASE_URL + `/records/delete_menu_record`;
                const res = await fetch(api, ApiServices.requestOptions("DELETE", itemsJSON, token));
                if (res.status >= 400) {
                    this.showAllertMessage("שגיאה במחיקת ההודעה ", "rgb(150, 19, 19)");
                } else if (res.status === 200) {
                    this.showAllertMessage("ההודעה נמחקה בהצלחה", "green");
                }
            } catch (error) {
                this.showSnackBar("Error delete record: " + error, "red");
            }
        },
        isFile(input) {
            return input instanceof File;
        },
        async saveLimitDateAndPriority() {
            try {
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ dateTime: this.datePicker, start_message: this.items[0].isPressable, temporary_message: this.items[1].isPressable});
                let api = process.env.VUE_APP_BASE_URL + `/records/save_limit_date_record_and_unpressed`;
                const res = await fetch(api, ApiServices.requestOptions("POST", itemsJSON, token));
                if (res.status >= 400) {
                    this.showAllertMessage("שגיאה  בשמירת השינויים ", "rgb(150, 19, 19)");
                } else if (res.status === 200) {
                    this.scheduleChange = false;
                    this.showAllertMessage("השינויים נשמרו בהצלחה!", "green");
                }
            } catch (error) {
                this.showSnackBar("Error save changes: " + error, "red");
            }
        }

    },
    watch: {
        datePicker() {
            this.scheduleChange = true
        }
    },
    mounted() {
        this.lineNumer = Auth.getLineNumber();
        this.getLimitDateAndUnpress();
        this.getRecords();
    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}

.attachContainer {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    width: 40%;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.527);
    border-radius: 8px;
    box-shadow: 0 3px 7px rgba(0, 0, 0, 0.3);

}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.attachIcon {
    width: 20%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgb(182, 178, 178);
}

.eee {
    position: absolute !important;
    top: 2px;
    right: 2px;
}

.deleteIcon {
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 1px solid rgb(182, 178, 178);
}

.deleteIcon:hover {
    cursor: pointer;
}

.audioButtons:hover {
    cursor: pointer;
}

.success-text {
    color: green;
    /* Change to your desired text color */
}

.failed-text {
    color: rgb(150, 19, 19);
    /* Change to your desired text color */
}


.widget.clearfix>h2 {
    /* display: none; */
}

.widget.clearfix {
    text-align: right;
}


div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}

#Squares {
    padding-right: 2%;
    width: 90%;
}

.box {
    position: relative;
    width: 30%;
    height: 220px;
    display: flex;
    flex-direction: column;
    /* background: url(images/bg_box.png);*/
    text-align: center;
    /*padding-top: 3%;*/
    float: right;
    margin-right: 1%;
    -webkit-box-shadow: 0px 60px 36px -72px rgba(0, 0, 0, 0.79);
    -moz-box-shadow: 0px 60px 36px -72px rgba(0, 0, 0, 0.79);
    box-shadow: 0px 60px 36px -72px rgba(0, 0, 0, 0.79);
    border-bottom: 3px solid #d1d2d4;
    border-left: 3px solid #d1d2d4;
    border-top: 1px solid #d1d2d4;
    margin-bottom: 1%;
    background: #eee;
}

.textBox {
    font-size: 18px;
    font-weight: bold;
    margin-top: 8px;
}



.h2box img {
    margin-top: 7%;
}



.h2box>h2>a {
    color: #0d2c6d !important;
    font-size: 30px;
    margin-bottom: 10%;
    font-family: heebo, arial;
    font-weight: bold;
    text-decoration: none;
}



.seemykdk>div>img {
    left: 1px;
    top: 1px;
}





/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
  