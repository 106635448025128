<template >
    <div>

        <v-row style="margin-right: 6%; display: flex; align-items: center;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
        </v-row>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table-settings" :headers="headers" :items="dataFormated" :search="search"
                    :footer-props="{ 'items-per-page-options': [200, 500], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span-settings">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows-settings">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells-settings">{{ item.action_name }} </td>
                            <td class="table-cells-settings">{{ item.mode }}</td>
                            <td class="table-cells-settings">{{ item.url }}</td>
                            <td class="table-cells-settings">{{ item.params }}</td>
                            <td class="table-cells-settings">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                            style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;"
                                            class="audioButtons" @click="openEditDialog(item, index)">
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                            </td>

                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;" class="audioButtons"
                                    @click="openEditDialog(item, index)">
                            </template>
                            <span>עריכה</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין הקלטות
                                מערכת</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>


            </div>
        </div>

        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>

        <EditDialog v-model="editDialog" v-if="editDialog" :itemToEdit="apiToEdit" @apiEdited="editedApiSaved" />

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import EditDialog from '@/components/settingspage/dialogs/ApiDialog'
import axios from 'axios'

export default {
    components: {
        SnackBar,
        EditDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        editDialog: false,
        progressShow: false,
        search: "",
        api: [{}],
        apiToEdit: {},
        overlay: false,
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'שם הפעולה', value: 'action_name' },
            { text: 'סטטוס', value: 'mode' },
            { text: 'webook', value: 'url' },
            { text: 'קבלת פרמטרים', value: 'params' },
            { text: '', value: 'actions', sortable: false },
        ],
    }),
    methods: {

        async getApiIntegrations() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_api_integrations`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.api = jsonObject;
                }
            } catch (error) {
                this.showSnackBar("Error get api's: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openEditDialog(item, index) {
            const apiToEdit = this.api.find(api => api._id === item._id);
            this.apiToEdit = apiToEdit
            this.editDialog = true
        },
        editedApiSaved(item, text, color){
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.api.findIndex(api => api._id === item._id);
            let g = Object.assign(this.api[index], item);
        }
    },
    computed: {
        dataFormated() {
            return this.api.map(item => ({
                ...item,
                action_name: item.action_name,
                params: item.params,
                _id: item._id,
                mode: item.mode === true ? 'פעיל' : 'מושבת',
                url: item.url ? 'קיים' : 'ריק',
            }));
        }
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getApiIntegrations()
    },
}
</script>
<style >
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table-settings.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 40px !important;
    text-align: center !important;
}

.table-headers-span-settings {
    font-family: 'Heebo' !important;
    font-size: 15px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows-settings {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells-settings {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table-settings tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}
</style>
    