<template>
  <v-dialog v-model="openMode" fullscreen hide-overlay transition="dialog-bottom-transition">
    <v-toolbar dark color="#0d2c6d">
      <v-btn icon dark @click="openMode = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>חבילות</v-toolbar-title>
    </v-toolbar>
    <!-- <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);"> -->
    <v-card style="background-color: #F3F6F9;">
      <div class="padding-responsive">
        <div class="padding-responsive" style="background-color: white;">
          <span style="font-size: 24px; color: #6A707E;">קניית חבילות</span>

          <v-row class="margin-responsive" :class="{ 'd-flex justify-space-around': plans.length > 2 }">
            <v-col cols="auto" sm="3" v-for="(item, index) in plans">

              <div class="box_credit_buy_package" style="width: 350px; max-width: 100%; background-color: white;">

                <div style="padding: 15px; display: flex; justify-content: center; background-color: #F3F6F9; ">
                  <span :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }" style="font-size: 22px;">{{ item.name
                  }}</span>
                </div>

                <div :style="{ backgroundColor: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }" style="height: 2px;"></div>

                <div style="display: flex; justify-content: center;">
                  <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל אנשי קשר בחבילה: {{
                    item.channels
                  }}</span>
                </div>
                <div style="display: flex; justify-content: center;">
                  <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל אחסון בחבילה: {{
                    item.storage }}GB</span>
                </div>
                <div style="display: flex; justify-content: center;">
                  <span style="margin-top: 10px; font-size: 18px; color: #6A707E;">סך הכל ערוצים בחבילה: {{
                    item.channels }}</span>
                </div>

                <div style="display: flex; justify-content: center;">
                  <span style="margin-top: 10px; font-size: 50px;"
                    :style="{ color: index % 2 === 0 ? '#0d2c6d' : '#fdbd29' }"><span
                      style="font-size: medium; font-weight: 600;">₪</span>{{ item.price }}</span>
                </div>

                <div style="display: flex; justify-content: center; margin-top: 15px; padding-bottom: 30px;">
                  <v-btn :color="index % 2 === 0 ? '#0d2c6d' : '#fdbd29'" :dark="index % 2 === 0"
                    @click="openPayDialog(item, index)" style="font-weight: 600;" rounded width="150">
                    <span>לרכישה</span>
                  </v-btn>
                </div>
              </div>
            </v-col>

          </v-row>

        </div>
      </div>
    </v-card>

    <PayDialog v-model="payDialog" v-if="payDialog" :planToPay="planToPay" />

    <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

  </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import PayDialog from '@/components/settingspage/dialogs/PayDialog'
import Auth from '@/services/auth.service.js'

export default {
  props: {
    value: { type: Boolean, default: false },

  },
  components: {
    SnackBar,
    PayDialog
  },
  data: () => ({
    progressShow: false,
    snackbar: false,
    snackbarColorBt: "green",
    snacbarText: "",
    planToPay: {},
    plans: [],
    payDialog: false,
    lineNumber: "",
    userDetails: {}
  }),

  methods: {
    actionConfirmed() {
      this.deleteItem();
    },

    async getAllPlans() {
      try {

        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/settings/get_all_plans`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = true;
        if (res.status >= 400) {
          this.progressShow = true;
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.plans = jsonObject;
          console.log(this.plans);
          this.progressShow = false;
        }
      } catch (error) {
        this.showSnackBar("Error get files: " + error, "red");
      }
    },
    closeDelete() {
      this.openMode = false;
    },
    showSnackBar(message, color) {
      this.snacbarText = message;
      this.snackbarColorBt = color;
      this.snackbar = true
    },
    async openPayDialog(item, index) {
      // this.planToPay = item;
      // this.payDialog = true;

      try {
        this.progressShow = true
        let MyJSON = JSON.stringify({ planDetails: item, userDetails: this.userDetails, lineNumber: this.lineNumber });
        let api = process.env.VUE_APP_BASE_URL + "/pay/get_pay_page"
        let token = localStorage.getItem("token");

        const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
        this.progressShow = false;
        const jsonObject = await response.json();
        if (response.status >= 400) {
          this.showSnackBar("שגיאת: " + jsonObject.message, "red");
        } else if (response.status === 200) {
          console.log(jsonObject.data);
          // Convert the string of key-value pairs into an object
          const params = new URLSearchParams(jsonObject.data);
          // Get the 'url' value
          const url = params.get('url');
          window.location.href = url;
        }
      } catch (error) {
        this.showSnackBar("Error: " + error, "red");
      }

    },
    async getIvrOwnerDetails() {

      try {
        this.progressShow = true;
        let token = localStorage.getItem("token");
        let api = process.env.VUE_APP_BASE_URL + `/account/get_account_details`;
        const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
        const jsonObject = await res.json();
        this.progressShow = true;
        if (res.status >= 400) {
          this.progressShow = true;
          this.showSnackBar("שגיאה בקבלת נתונים", "red");
        } else if (res.status === 200) {
          this.userDetails = jsonObject;
          this.progressShow = false;
        }
      } catch (error) {
        this.showSnackBar("Error get files: " + error, "red");
      }

    },

  },
  computed: {
    openMode: {
      get() {
        return this.value
      },
      set(v) {
        return this.$emit('input', v)
      }
    },
  },
  mounted() {
    this.lineNumber = Auth.getLineNumber();
    this.getAllPlans();
    this.getIvrOwnerDetails();
  },
}
</script>
<style scoped>
.gradient-button-background {
  background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.box_credit_buy_package {
  box-shadow: -1px 3px 5px 1px rgb(240 240 240);
  -webkit-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
  -moz-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
  border-radius: 5px;
}

.padding-responsive {
  padding: 10px;
}

@media (min-width: 600px) {
  .padding-responsive {
    padding: 30px;
  }

  .margin-responsive {
    margin: 10px;
  }
}
</style>
    