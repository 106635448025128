<template >
    <div>

        <div style=" width: 100%; margin-bottom: 20px; border: 1px solid #dad7d7; display: none;" id="allert-upload">
            <span style="color: green; font-weight: bold; font-size: medium;">
            </span>
        </div>

        <div style=" width: 90%; margin-right: 4.5%; margin-bottom: 10px;">
            <v-row style="display: flex; align-items: center;">
                <v-col cols="12" sm="5" :style="$vuetify.breakpoint.smAndUp ? 'padding-bottom: 0px !important;' : ''">
                    <div style="display: flex; align-items: center;">
                        <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                            style="margin-left: 20px; margin-right: 20px;" class="search">
                        </v-text-field>
                        <span style=" font-weight: 600;">סך הכל {{ records.length }} הקלטות</span>
                    </div>
                </v-col>

                <v-col cols="12" sm="7" :style="$vuetify.breakpoint.smAndUp ? 'padding-bottom: 0px !important;' : ''">
                    <div style="direction: ltr;">
                        <v-btn color="#adacaa2c" class="gradient-button-background" @click="addRecordDialog = true"
                            style="font-weight: 500; font-size: medium;">הוסף
                            הקלטה</v-btn>
                    </div>

                </v-col>
            </v-row>

        </div>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">

                <v-data-table id="table1" :headers="headers" :items="records" sort-by="customCreatedAt" sort-desc :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ item.integer_id }}</td>
                            <td class="table-cells">{{ item.caller_name }}</td>
                            <td class="table-cells">{{ item.caller_id }}</td>
                            <td class="table-cells">
                                <div style="display: flex; justify-content: space-between;">
                                    {{ item.type }}
                                    <!-- <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                        </template>
                                        <span style="color: white;">רשימת נמענים:<br><span style="color: white;"
                                                v-for="dest in item.private_message.dest_number_with_names">שם:
                                                {{ dest.name }}, טלפון: {{ dest.dest_number }}<br></span></span>
                                    </v-tooltip> -->
                                    <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה משותפת'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                        </template>
                                        <span style="color: white;">שותף ממערכת מספר: {{ item.shared_from_ivr }}</span>
                                    </v-tooltip>
                                </div>

                            </td>
                            <td class="table-cells"><span style="font-size: small;">{{ item.createdAt }}</span></td>
                            <td class="table-cells"><span style="font-size: small;">{{ item.customCreatedAt }}</span></td>
                            <td class="table-cells">{{ item.record_duration }}</td>
                            <td class="table-cells">{{ item.record_name_original }}</td>

                            <td class="table-cells">
                                <div style="display: flex; align-items: center;">
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_pause.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="pause(item)">
                                        </template>
                                        <span>הפסק</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item)">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                    <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_download_file.svg" v-on="on"
                                                style="margin: 10px; width: 20px; cursor: pointer;" class="audioButtons"
                                                @click="download(item)">
                                        </template>
                                        <span>הורדה</span>
                                    </v-tooltip>
                                </div>

                            </td>
                            <td class="table-cells">
                                <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_statistic.svg" v-on="on"
                                            style="margin: 10px; cursor: pointer;" class="audioButtons"
                                            @click="openStatisticDialog(item, index)">
                                    </template>
                                    <span>סטטיסטיקת שמיעה</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                            style="margin: 10px; cursor: pointer;" class="audioButtons"
                                            @click="openDeleteDialog(item, index)">
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>


                    <!-- this for phone mode -->
                    <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_statistic.svg" v-on="on"
                                    style="margin: 10px; cursor: pointer;" class="audioButtons"
                                    @click="openStatisticDialog(item, index)">
                            </template>
                            <span>סטטיסטיקת שמיעה</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_delete.svg" v-on="on" style="margin: 10px; cursor: pointer;"
                                    class="audioButtons" @click="openDeleteDialog(item, index)">
                            </template>
                            <span>מחיקה</span>
                        </v-tooltip>
                    </template>

                    <!-- this for phone mode -->
                    <template v-slot:item.record_name="{ item, index }">
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="stop(item)">
                            </template>
                            <span>עצור</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_pause.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="pause(item)">
                            </template>
                            <span>הפסק</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/upload/icon_play.svg" v-on="on"
                                    style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                    @click="play(item)">
                            </template>
                            <span>הפעל</span>
                        </v-tooltip>
                        <v-tooltip bottom v-if="item.type !== 'הודעה פרטית'">
                            <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_download_file.svg" v-on="on"
                                        style="margin-bottom: 5px; width: 20px; cursor: pointer;" class="audioButtons" @click="download(item)">
                            </template>
                            <span>הורדה</span>
                        </v-tooltip>
                    </template>

                    <!-- this for phone mode -->
                    <template v-slot:item.type="{ item, index }">
                        <div style="display: flex; justify-content: space-between;">
                            {{ item.type }}
                            <!-- <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה פרטית'">
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                </template>
                                <span style="color: white;">רשימת נמענים:<br><span style="color: white;"
                                        v-for="dest in item.private_message.dest_number_with_names">שם:
                                        {{ dest.name }}, טלפון: {{ dest.dest_number }}<br></span></span>
                            </v-tooltip> -->
                            <v-tooltip bottom color="#0d2c6d" v-if="item.type === 'הודעה משותפת'">
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_details.svg" v-on="on" style="cursor: pointer;">
                                </template>
                                <span style="color: white;">שותף ממערכת מספר: {{ item.shared_from_ivr }}</span>
                            </v-tooltip>
                        </div>
                    </template>


                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין
                                הקלטות</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>

                <template>
                    <v-overlay :value="overlay">
                        <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
                    </v-overlay>
                </template>

            </div>
        </div>
        <DeleteItemDialog v-model="deleteDialog" v-if="deleteDialog" :item="itemToDelete" @itemDeleted="itemDeleted">
        </DeleteItemDialog>

        <AddRecordDialog v-model="addRecordDialog" v-if="addRecordDialog" @recordAdded="recordAdded">
        </AddRecordDialog>

        <ListeningStatisticDialog v-model="listeningStatisticDialog" v-if="listeningStatisticDialog"
            :listeningDetails="listeningDetails" :recordDuration="recordDuration" />


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'
import DeleteItemDialog from '@/components/upload/dialogs/DeleteItemDialog'
import ListeningStatisticDialog from '@/components/upload/dialogs/ListeningStatisticDialog'
import AddRecordDialog from '@/components/upload/dialogs/AddRecordDialog'

export default {
    components: {
        SnackBar,
        DeleteItemDialog,
        AddRecordDialog,
        ListeningStatisticDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        itemToDelete: {},
        deleteDialog: false,
        listeningStatisticDialog: false,
        listeningDetails: [],
        recordDuration: "",
        addRecordDialog: false,
        overlay: false,
        search: "",
        records: [],
        headers: [
            { text: "קובץ מס'", value: "integer_id" },
            { text: 'שם', align: 'start', value: 'caller_name' },
            { text: 'טלפון', value: 'caller_id' },
            { text: 'שלוחה', value: 'type' },
            { text: 'תאריך הקלטה', value: 'createdAt' },
            { text: 'תאריך העלאה לשלוחה', value: 'customCreatedAt' },
            { text: 'זמן הקלטה', value: 'record_duration' },
            { text: 'שם הקלטה', value: 'record_name_original' },
            { text: 'שמע', value: 'record_name' },
            { text: 'פעולות', value: 'actions', sortable: false },

        ],
    }),
    methods: {

        async getRecords() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/records/get_records`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.records = jsonObject;
                    console.log(this.records);
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.records = this.records.map((record, index) => {
                return {
                    ...record,
                    customCreatedAt: utils.getFormatDate(record.customCreatedAt),
                    createdAt: utils.getCurrentDate(record.createdAt),
                    record_duration_original: record.record_duration,
                    record_duration: this.formatDuration(record.record_duration),
                    type: record.type === "regular" ? "שלוחת הודעות" : record.type === "private" ? "הודעה פרטית" : "הודעה משותפת",
                    caller_id: record.caller_id === "WEBSITE" ? "אתר הניהול" : record.caller_id,
                    position: index + 1 // Adding a position property
                }
            });
        },
        formatDetailsSingle(record) {
            return {
                ...record,
                customCreatedAt: utils.getCurrentDate(record.customCreatedAt),
                createdAt: utils.getCurrentDate(record.createdAt),
                record_duration: this.formatDuration(record.record_duration),
                type: record.type === "regular" ? "שלוחת הודעות" : record.type === "private" ? "הודעה פרטית" : "הודעה משותפת",
                position: this.records.length + 1, // Adding a position property
                caller_id: "אתר הניהול"

            }

        },
        async setAudio(record) {
            try {
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/` + record.record_name;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.blob();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בהפעלת ההקלטה", "red");
                } else if (res.status === 200) {
                    const url = URL.createObjectURL(jsonObject);
                    record.record_link = url;
                    record.record_audio = new Audio(url);
                    const index = this.records.findIndex(record1 => record1._id === record._id);
                    let g = Object.assign(this.records[index], record);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        async setNewAudio(record) {
            console.log(record);
            try {
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/` + record.record_name;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.blob();
                if (res.status >= 400) {
                    // this.showSnackBar("שגיאה בהפעלת ההקלטה", "red");
                } else if (res.status === 200) {
                    const url = URL.createObjectURL(jsonObject);
                    record.record_link = url;
                    record.record_audio = new Audio(url);
                    record = this.formatDetailsSingle(record);
                    this.records.push(record);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        async play(item) {
            if (!item.record_audio) {
                this.overlay = true;
                await this.setAudio(item);
                this.overlay = false;
            }
            item.record_audio.play();
            item.record_audio.addEventListener('ended', () => {
                this.stop(item);
            });
            item.record_audio.addEventListener('error', (event) => {
                this.stop(item);
                this.showSnackBar('שגיאה: קובץ שמע לא תקין', "red");
            });
        },
        stop(item) {
            if (item.record_audio) {
                item.record_audio.pause();
                item.record_audio.currentTime = 0;
            }
        },
        pause(item) {
            if (item.record_audio) {
                item.record_audio.pause();
            }
        },
        async download(item) {
            if (!item.record_audio) {
                this.overlay = true;
                await this.setAudio(item);
                this.overlay = false;
            }
            // Create a link for downloading
            const downloadLink = document.createElement("a");
            downloadLink.href = item.record_link; // URL to the audio file
            downloadLink.download = item.record_name; // The name of the downloaded file

            // Append link to the body, click it, and then remove it
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);

        },
        openDeleteDialog(item, index) {
            this.itemToDelete = item
            this.deleteDialog = true
        },
        itemDeleted(id, text, color) {
            if (id === "") {
                this.showSnackBar(text, color)
                return
            }
            // Use splice to remove the item at the specified index
            const index = this.records.findIndex(record => record._id === id);
            this.records.splice(index, 1);
            this.showSnackBar(text, color)
        },
        recordAdded(record, text, color) {
            if (record === "") {
                this.showSnackBar(text, color)
                return
            }
            this.setNewAudio(record);
            this.showSnackBar(text, color)
        },
        openStatisticDialog(item, index) {
            this.listeningDetails = item.listening;
            this.recordDuration = item.record_duration_original;
            this.listeningStatisticDialog = true
        }
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getRecords();

    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

#boxContainer {
    width: 95%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 18px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 16px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    