<template >
    <v-container style="margin-top: 50px; ">

        <div style="display: flex; justify-content: center; margin-bottom: 50px;">
            <span style="font-size: large; font-weight: 600;">פרטי המשתמש:</span>
        </div>


        <v-row style="display: flex; align-items: center; margin-right: 20px; ">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>שם משתמש:</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field disabled style="color: black !important; font-weight: bold !important;"
                    v-model="details.line_number" hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>שם מוסד/עסק:</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.owner_details.business_name" background-color="white" hide-details outlined :rules="[v => !!v || 'שדה חובה']" required
                    dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>ת.ז/ח.פ</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.owner_details.business_id" background-color="white" hide-details outlined :rules="[v => !!v || 'שדה חובה']" required
                    dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>שם איש קשר:</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.owner_details.name" background-color="white" hide-details outlined :rules="[v => !!v || 'שדה חובה']" required
                    dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>טלפון</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.owner_details.phone" background-color="white" type="number" hide-spin-buttons :rules="[v => !!v || 'שדה חובה']" required
                    hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>אימייל</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.email" background-color="white" hide-details outlined dense></v-text-field>
            </v-col>
        </v-row>

        <v-row style="display: flex; align-items: center; margin-right: 20px;">
            <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                <span>כתובת</span>
            </v-col>
            <v-col cols="6" md="3" sm="4" style="width: 300px; padding-right: 0px;">
                <v-text-field v-model="details.owner_details.address" background-color="white" hide-details outlined
                    dense></v-text-field>
            </v-col>
        </v-row>



        <div
            style="padding-bottom: 50px; padding-top: 5px; margin-top: 50px; background-color: #eee; border: 3px solid white;">

            <div style="display: flex; justify-content: center; margin-bottom: 50px;">
                <span style="font-size: large; font-weight: 600;">סיסמאות וגישה</span>
            </div>

            <v-row style="display: flex; align-items: center; margin-right: 20px; ">
                <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                    <span>סיסמה טלפונית</span>
                </v-col>
                <v-col cols="6" md="3" sm="4" style="width: 300px;">
                    <v-text-field background-color="white" v-model="details.phone_password" maxlength="4" hide-spin-buttons :rules="[v => !!v || 'שדה חובה']" required
                        hide-details outlined dense></v-text-field>
                </v-col>
            </v-row>

            <v-row style="display: flex; align-items: center; margin-right: 20px;">
                <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                    <span>סיסמה אתר הניהול</span>
                </v-col>
                <v-col cols="6" md="3" sm="4" style="width: 300px;">
                    <v-text-field v-model="passObj.password" hide-spin-buttons background-color="white" hide-details
                        outlined dense></v-text-field>
                </v-col>
            </v-row>

            <v-row style="display: flex; align-items: center; margin-right: 20px;">
                <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                    <span>אימות סיסמה</span>
                </v-col>
                <v-col cols="6" md="3" sm="4" style="width: 300px;">
                    <v-text-field v-model="passObj.repeat_password" background-color="white" hide-spin-buttons hide-details
                        outlined dense></v-text-field>
                </v-col>
            </v-row>
            <v-row style="display: flex; align-items: center; margin-right: 20px;">
                <v-col cols="6" md="1" sm="2" style="padding-left: 0px;">
                    <span>API טוקן</span>
                </v-col>
                <v-col cols="6" md="3" sm="4" style="width: 300px;">
                    <v-text-field v-model="details.api_token" disabled background-color="white" hide-spin-buttons hide-details
                        outlined dense></v-text-field>
                </v-col>
            </v-row>

        </div>

        <v-row v-if="progressShow" style="margin-top: 20px;">
            <v-col class="d-flex justify-center">
                <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
            </v-col>
        </v-row>

        <v-row style="margin-bottom: 70px; margin-top: 30px; margin-right: 20px; ">
            <v-col cols="12">
                <v-btn class="gradient-yellow-button-background" style="margin-left: 15px; font-size: medium;"
                    @click="saveChanges()">
                    שמור שינויים
                </v-btn>
            </v-col>

        </v-row>


        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-container>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'

export default {
    components: {
        SnackBar,

    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        details: {
            owner_details: {
                name: "",
                business_name: "",
                phone: "",
                address: "",
                business_id: ""
            },
            line_number: "",
            email: "",
            phone_password: "",
            api_token: "",
        },
        passObj: {
            password: "",
            repeat_password: "",
        }

    }),
    methods: {

        async getAccountDetails() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/account/get_account_details`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.details = jsonObject;
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        async saveChanges() {

            try {
                if (this.passObj.password) {
                    if (this.passObj.password !== this.passObj.repeat_password) {
                        return this.showSnackBar("נא לאמת את הסיסמה החדשה", "red");
                    }

                    if (this.passObj.password.length < 9 || this.passObj.password.length > 20) {
                        return this.showSnackBar("הסיסמה חייבת לכלול לפחות 9 תווים ולא יותר מ 20", "red");
                    }
                }

                this.progressShow = true;
                const detailsJSON = JSON.stringify({ details: this.details, passObj: this.passObj });
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/account/save_changes`;
                const res = await fetch(api, ApiServices.requestOptions("PUT", detailsJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                console.log(jsonObject);
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בשמירת השינויים: " + jsonObject.message, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("השינויים עודכנו בהצלחה!", "green");
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },

    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getAccountDetails();
    },
}
</script>
<style >
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    