<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <v-card-title class="text-h5">הגדרות צינתוקים</v-card-title>
            <div style="margin-right: 30px;">
                <v-checkbox v-model="itemToChange.recieve_calls" disabled hide-details
                    :label="`אישר קבלת צינתוקים:`"></v-checkbox>
            </div>
            <div style="margin-right: 30px;" v-if="itemToChange.confirm_calls_date">
                <dpan>תאריך אישור: {{ itemToChange.confirm_calls_date }}</dpan>
            </div>
            <div style="margin-right: 30px; margin-bottom: 20px;">
                <v-checkbox v-model="itemToChange.is_muted" hide-details :label="`צינתוק מושתק:`"></v-checkbox>
            </div>
            <div style="margin-right: 30px; margin-bottom: 10px;">
                <sapn style="font-size: larger;">הגדר זמן להשתקה:</sapn>
            </div>
            <v-row style="margin-bottom: 20px; margin-left: 20px; margin-right: 18px; align-items: center;">
                <v-col cols="12" sm="4" style="padding-left: 0px;">
                    <sapn style="padding-left: 0px;">השתק עד לתאריך: </sapn>
                </v-col>
                <v-col cols="12" sm="7" style="padding-left: 0px; padding-right: 0px;">
                    <v-text-field v-model="itemToChange.mute_until" type="datetime-local" hide-details dense></v-text-field>
                </v-col>
            </v-row>
            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>
            <div style="display: flex; justify-content: center !important; padding-bottom: 25px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px; font-size: medium;"
                    @click="saveSettings()">
                    שמירה
                </v-btn>
                <v-btn :color="'gradient-button-background'" @click="openMode = false" style="font-size: medium;">
                    ביטול
                </v-btn>
            </div>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Utils from '@/util/utils'

export default {
    props: {
        item: Object,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        checkbox: false,
        itemToChange: {},
    }),

    methods: {
        formatDate() {
            if (this.itemToChange.mute_until) {
                this.itemToChange.mute_until = Utils.getFormatDate(this.itemToChange.mute_until);
            } else {
                this.itemToChange.mute_until = "";
            }

            if (this.itemToChange.confirm_calls_date) {
                this.itemToChange.confirm_calls_date = Utils.getCurrentDate(this.itemToChange.confirm_calls_date);
            }
        },
        async saveSettings() {
            try {
                if (!this.itemToChange.is_muted) {
                    this.itemToChange.mute_until = null
                }
                if (this.itemToChange.is_muted && !this.itemToChange.mute_until) {
                    this.$emit("itemSettingsChange", "", "שגיאה: חובה לבחור תאריך לסיום ההשתקה", "red");
                    return;
                }
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ item: this.itemToChange });

                let api = process.env.VUE_APP_BASE_URL + "/subscribes/update_settings";
                const res = await fetch(api, ApiServices.requestOptions("PUT", itemsJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.$emit("itemSettingsChange", "", "שגיאה בשמירת הגדרות: ", "red");
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.$emit("itemSettingsChange", this.itemToChange, "ההגדרות עודכנו בהצלחה!", "green");
                    this.progressShow = false;
                    this.openMode = false;
                }
            } catch (error) {
                this.progressShow = false;
                this.$emit("itemSettingsChange", "", "שגיאה בשמירת הנתונים: " + error, "red");
            }
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    created() {
        this.itemToChange = { ...this.item };
        console.log(this.itemToChange);
        this.formatDate()
        console.log(this.itemToChange);
    },
}
</script>
<style scoped>
.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}
</style>
    