<template>
    <v-dialog v-model="openMode" max-width="500px">
        <v-card style="padding-left: 30px; padding-right: 30px; background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <div style="text-align: center; padding: 5px;">
                <span class="text-h5">רשימת קווים</span>
            </div>

            <div style=" border-bottom: 1px solid rgba(117, 116, 116, 0.452);">
                <v-data-table id="table1"  :headers="headers" :items="lines" hide-default-footer mobile-breakpoint="100">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template  #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.line_number }}</td>
                            <td class="table-cells">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                            style="margin: 10px; cursor: pointer;" class="audioButtons"
                                            @click="disconnectLine(item, index)">
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>

            <v-row no-gutters style=" margin-top: 100px; margin-bottom: 10px;">
                <v-col cols="12" sm="6">
                    <v-text-field v-model="newLine" hide-details outlined type="number" hide-spin-buttons dense label="הוסף מספר חדש"  
                    class="negative-elevation" background-color="white" :rules="[v => !!v || 'שדה חובה']" required></v-text-field>
                </v-col>
            </v-row>

            <div style="display: flex; justify-content: start !important; padding-top: 20px; padding-bottom: 20px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px;" @click="actionConfirmed">הוסף מספר</v-btn>
                <v-btn :color="'gradient-button-background'"  @click="closeDelete">סגור</v-btn>
            </div>

            <v-row v-if="progressShow">
                <v-col class="d-flex justify-center">
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </v-col>
            </v-row>

        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
  
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        ivr: Object,
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        newLine: "",
        lines: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'מספר', align: 'start', value: 'line_number' },
            { text: 'פעולות', value: 'actions', sortable: false },
        ],
    }),

    methods: {
        actionConfirmed() {
            this.connectLine();
        },
        async getIvrLines() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const ivrJSON = JSON.stringify({ ivr: this.ivr});

                let api = process.env.VUE_APP_BASE_URL + "/admin_api/get_ivr_lines";
                const res = await fetch(api, ApiServices.requestOptions("POST", ivrJSON, token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת הקווים", "red");
                    this.progressShow = false;
                } else if (res.status === 200) {
                    this.progressShow = false;
                    this.lines = jsonObject;
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בקבלת הקווים: " + error, "red");
            }
        },
        async connectLine() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ ivr: this.ivr, newLine: this.newLine});

                let api = process.env.VUE_APP_BASE_URL + "/admin_api/connect_ivr_line";
                const res = await fetch(api, ApiServices.requestOptions("POST", itemsJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בהוספת הקו: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("הקו נוסף בהצלחה!", "green");
                    this.lines.push(jsonObject.updatedLine)
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בהוספת הקו: " + error, "red");
            }
        },
        async disconnectLine(item, index) {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const itemsJSON = JSON.stringify({ ivr: this.ivr, lineToDisconnect: item.line_number});

                let api = process.env.VUE_APP_BASE_URL + "/admin_api/disconnect_ivr_line";
                const res = await fetch(api, ApiServices.requestOptions("POST", itemsJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בהוספת הקו: " + jsonObject, "red");
                } else if (res.status === 200) {
                    this.showSnackBar("הקו נמחק בהצלחה!", "green");
                    this.lines.splice(index, 1)
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("שגיאה בהוספת הקו: " + error, "red");
            }
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        this.getIvrLines();
    },
}
</script>
<style scoped>
.negative-elevation {
    /* Example of a custom style to mimic negative elevation */
    box-shadow: inset 0px 4px 6px rgba(0, 0, 0, 0.1);
    /* Other styles can be added here as needed */
}


.gradient-button-background {
  background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
  background: linear-gradient(0deg, rgba(255,185,26,1) 1%, rgba(255,185,26,0.8127626050420168) 70%);
}
</style>
    