<template >
    <div style="margin-top: 100px; margin-right: 20px;">

        <v-row style="margin: 10px;" :class="{ 'd-flex justify-space-around': reports.length > 2 }">
            <v-col cols="auto" sm="3" v-for="(item, index) in reports" class="mb-5 mt-5 custom-elevation"
                style="margin-left: 10px !important; margin-right: 10px !important; width: 300px; max-width: 100%; background: rgb(241, 241, 241)">
                <div style="display: flex; justify-content: center;">
                    <span style="font-weight: 600; font-size: x-large;">דוח שיחות</span>
                </div>

                <div style="display: flex; justify-content: center;">
                    <span style="margin-top: 10px; font-size: xx-large;">{{ item.month }}</span>
                </div>

                <div style="display: flex; justify-content: space-around; margin-top: 10px;">
                    <span style="font-weight: 600;">{{ item.totalDuration }} דקות</span>
                    <span style="font-weight: 600;">{{ item.entrance }} כניסות</span>
                </div>

                <div style="display: flex; justify-content: center; margin-top: 15px;">
                    <v-btn class="gradient-button-background" @click="exportExampleExcel(index)" style="font-weight: 600; ">
                        <img src="@/assets/reports/icon_download.svg">
                        <span style="margin-right: 7px;">
                            הורדת דוח
                        </span>
                    </v-btn>
                </div>
            </v-col>

        </v-row>

        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import ContextDetailsDialog from '@/components/callHistory/dialogs/ContextDetailsDialog.vue'
import Auth from '@/services/auth.service.js'
import * as XLSX from 'xlsx';

export default {
    components: {
        SnackBar,
        ContextDetailsDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        overlay: false,
        intervalId: null, // Add a property to store the interval ID
        reports: [],

    }),
    methods: {

        async getReports() {
            try {
                this.overlay = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/reports/get_reports_details`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                if (res.status >= 400) {
                    this.overlay = false;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    await this.groupCdr(jsonObject);
                    this.overlay = false;
                }
            } catch (error) {
                this.overlay = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        groupCdr(jsonObject) {
            // Grouping reports by month and year, only if they have a 'start' field
            let groupedReports = jsonObject.reduce((acc, report) => {
                // Check if 'start' field exists in the report
                if (report.start) {
                    let date = new Date(report.start);
                    let yearMonth = `${String(date.getMonth() + 1).padStart(2, '0')}/${date.getFullYear()}`;
                    if (!acc[yearMonth]) {
                        acc[yearMonth] = {
                            reports: [],
                            totalDuration: 0, // Initialize total duration for each group
                            entrance: 0 // Initialize entrance count for each group
                        };
                    }
                    acc[yearMonth].reports.push(report);
                    acc[yearMonth].entrance++;

                    // Convert duration string to number (in seconds) and add to totalDuration, if it's a valid number
                    if (report.duration && !isNaN(parseInt(report.duration, 10))) {
                        let durationInSeconds = parseInt(report.duration, 10);
                        acc[yearMonth].totalDuration += durationInSeconds;
                    }
                }
                return acc;
            }, {});

            // Converting the grouped reports into an array and sorting by month and year in descending order
            this.reports = Object.keys(groupedReports).sort((a, b) => {
                // Sorting based on year and month in descending order
                return a.split('/').reverse().join('') < b.split('/').reverse().join('') ? 1 : -1;
            }).map(key => {
                return {
                    month: key,
                    reports: groupedReports[key].reports,
                    totalDuration: Math.round(groupedReports[key].totalDuration / 60), // Convert to minutes
                    entrance: groupedReports[key].entrance
                };
            });
        },
        async exportExampleExcel(index) {
            try {

                const role = Auth.getUserRole();
                // Transform data
                const transformedData = this.reports[index].reports.map((report, idx) => {
                    if (role === "user") {
                        // For 'user' role, include 'שם' as the second field
                        return {
                            "#": idx + 1,
                            "תאריך": report.start,
                            "שם": report.callerName,
                            "טלפון": report.src,
                            "יעד": report.line_number,
                            "זמן (שניות)": report.duration
                        };
                    } else {
                        // For other roles, exclude 'שם'
                        return {
                            "#": idx + 1,
                            "תאריך": report.start,
                            "טלפון": report.src,
                            "יעד": report.line_number,
                            "זמן (שניות)": report.duration
                        };
                    }
                });


                // Create workbook and worksheet
                const workbook = XLSX.utils.book_new();
                const worksheet = XLSX.utils.json_to_sheet(transformedData);


                // Append worksheet to workbook
                XLSX.utils.book_append_sheet(workbook, worksheet, "Reports");

                // Write and download the Excel file
                XLSX.writeFile(workbook, `${this.reports[index].month}.xlsx`);
            } catch (error) {
                console.error("Error exporting Excel file:", error);
            }
        }
        ,
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    mounted() {
         this.getReports();
    },
}
</script>
<style >
body {
    height: 100vh;
    margin: 0;
}

#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}


.custom-elevation {
    box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
    /* Adjust values as needed */
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    