<template >
    <div>

        <v-row style="margin-right: 6%; display: flex; align-items: center;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
        </v-row>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table-settings" :headers="headers" :items="systemRecords" :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 200, 500], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span-settings">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows-settings">
                            <td class="table-cells-settings">{{ item.fileName }}</td>
                            <td class="table-cells-settings">
                                <!-- עברית -->
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item, 'he')">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="table-cells-settings">
                                <!-- אנגלית -->
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item, 'en')">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="table-cells-settings">
                                <!-- אידיש ישראלי -->
                                <div
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item, 'yi_il')">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="table-cells-settings">
                                <!-- אידיש אמריקאי -->
                                <div
                                    style="display: flex; justify-content: center; align-items: center;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item, 'yi_us')">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="table-cells-settings">
                                <!-- מותאם אישית  -->
                                <div style="display: flex; justify-content: center; align-items: center;">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="stop(item)">
                                        </template>
                                        <span>עצור</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_play.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="play(item, null)">
                                        </template>
                                        <span>הפעל</span>
                                    </v-tooltip>

                                    <div>
                                        <label :for="'fileInput' + index">
                                            <v-tooltip bottom>
                                                <template v-slot:activator="{ on }">
                                                    <img src="@/assets/upload/icon_upload-file.svg" v-on="on"
                                                        style="cursor: pointer; margin: 10px; width: 19px;"
                                                        class="audioButtons">
                                                </template>
                                                <span>העלאת קובץ</span>
                                            </v-tooltip>
                                        </label>
                                        <v-file-input :id="'fileInput' + index" prepend-icon="" v-model="fileInput1"
                                            @change="uploadFile(item)" style="display: none;"></v-file-input>
                                    </div>

                                    <v-tooltip bottom v-if="item.costumeExist">
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                                style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                                @click="deleteCostumeRecord(item)">
                                        </template>
                                        <span>מחיקה</span>
                                    </v-tooltip>
                                </div>
                            </td>
                            <td class="table-cells-settings">{{ item.fileContent }}</td>
                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.he="{ item, index }">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="stop(item)">
                                </template>
                                <span>עצור</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_play.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="play(item, 'he')">
                                </template>
                                <span>הפעל</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.en="{ item, index }">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="stop(item)">
                                </template>
                                <span>עצור</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_play.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="play(item, 'en')">
                                </template>
                                <span>הפעל</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.yi_il="{ item, index }">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="stop(item)">
                                </template>
                                <span>עצור</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_play.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="play(item, 'yi_il')">
                                </template>
                                <span>הפעל</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.yi_us="{ item, index }">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="stop(item)">
                                </template>
                                <span>עצור</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_play.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="play(item, 'yi_us')">
                                </template>
                                <span>הפעל</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.costume="{ item, index }">
                        <div style="display: flex; justify-content: center; align-items: center;">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_stop.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="stop(item)">
                                </template>
                                <span>עצור</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_play.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="play(item, null)">
                                </template>
                                <span>הפעל</span>
                            </v-tooltip>

                            <div>
                                <label :for="'fileInput' + index">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <img src="@/assets/upload/icon_upload-file.svg" v-on="on"
                                                style="cursor: pointer; margin: 10px; width: 19px;" class="audioButtons">
                                        </template>
                                        <span>העלאת קובץ</span>
                                    </v-tooltip>
                                </label>
                                <v-file-input :id="'fileInput' + index" prepend-icon="" v-model="fileInput1"
                                    @change="uploadFile(item)" style="display: none;"></v-file-input>
                            </div>

                            <v-tooltip bottom v-if="item.costumeExist">
                                <template v-slot:activator="{ on }">
                                    <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                        style="margin: 10px; width: 14px; cursor: pointer;" class="audioButtons"
                                        @click="deleteCostumeRecord(item)">
                                </template>
                                <span>מחיקה</span>
                            </v-tooltip>
                        </div>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין הקלטות
                                מערכת</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>


            </div>
        </div>

        <template>
            <v-overlay :value="overlay">
                <v-progress-circular indeterminate size="84" color="#0d2c6d"></v-progress-circular>
            </v-overlay>
        </template>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import axios from 'axios'

export default {
    components: {
        SnackBar
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        deleteDialog: false,
        search: "",
        systemRecords: [],
        overlay: false,
        fileInput1: null,
        headers: [
            { text: 'קוד הודעה', align: 'start', value: 'fileName' },
            { text: 'עברית', value: 'he' },
            { text: 'אנגלית', value: 'en' },
            { text: 'אידיש ישראלי', value: 'yi_il', width: "140px"  },
            { text: 'אידיש אמריקאי', value: 'yi_us', width: "150px"  },
            { text: 'מותאם אישית', value: 'costume' },
            { text: 'תוכן ההקלטה', value: 'fileContent' },
        ],
    }),
    methods: {

        async getSystemRecords() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_system_records`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.systemRecords = jsonObject;
                }
            } catch (error) {
                this.showSnackBar("Error get subscribes: " + error, "red");
            }
        },
        async setAudio(record, language) {
            try {
                console.log(language);
                let token = localStorage.getItem("token");
                let api;
                const firstChar = record.fileName.charAt(0);
                if (record.costumeExist && !language) {
                    api = process.env.VUE_APP_BASE_URL + `/users_records/${this.lineNumber}/${firstChar}/` + record.fileName;
                } else {
                    api = process.env.VUE_APP_BASE_URL + `/costume_records/${language}/costume_records/${firstChar}/` + record.fileName;
                }
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.blob();
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בהפעלת ההקלטה", "red");
                    record.record_link = "";
                    record.record_audio = "";
                } else if (res.status === 200) {
                    const url = URL.createObjectURL(jsonObject);
                    record.record_link = url;
                    record.record_audio = new Audio(url);
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },

        async play(item, lang) {

            this.overlay = true;
            await this.setAudio(item, lang);
            this.overlay = false;

            if (item.record_audio) {
                item.record_audio.play();
                item.record_audio.addEventListener('ended', () => {
                    this.stop(item);
                });
                item.record_audio.addEventListener('error', (event) => {
                    this.stop(item);
                    this.showSnackBar('שגיאה: קובץ שמע לא תקין', "red");
                });
            }
        },
        stop(item) {
            if (item.record_audio) {
                item.record_audio.pause();
                item.record_audio.currentTime = 0;
            }
        },
        async uploadFile(item) {
            try {
                if (this.fileInput1 instanceof File) {

                    this.overlay = true;
                    let token = localStorage.getItem("token");
                    let api = process.env.VUE_APP_BASE_URL + `/settings/change_system_record`;

                    const formData = new FormData();
                    formData.append("record", this.fileInput1);
                    formData.append("recordName", item.fileName);
                    const response = await axios.post(api, formData, {
                        headers: {
                            'Authorization': 'Bearer ' + token,
                            'Content-Type': 'multipart/form-data'
                        }
                    });
                    if (response.status >= 400) {
                        this.overlay = false;
                        this.showSnackBar("שגיאה: לא ניתן להעלות את קובץ " + this.fileInput1.name, "red");
                    } else if (response.status === 200) {
                        this.overlay = false;
                        this.showSnackBar("הקובץ הועלה בהצלחה!", "green");
                        const index = this.systemRecords.findIndex(record => record.id === item.id);
                        this.systemRecords[index].costumeExist = true;
                    }

                }
            } catch (error) {
                this.overlay = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        async deleteCostumeRecord(item) {
            try {
                this.overlay = true;
                let token = localStorage.getItem("token");
                const recordJSON = JSON.stringify({ item: item });
                let api = process.env.VUE_APP_BASE_URL + `/settings/delete_system_records`;
                const res = await fetch(api, ApiServices.requestOptions("DELETE", recordJSON, token));
                this.overlay = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה במחיקת הקובץ", "red");
                } else if (res.status === 200) {
                    this.showSnackBar("ההקלטה נמחקה בהצלחה!", "green");
                    const index = this.systemRecords.findIndex(record => record.id === item.id);
                    this.systemRecords[index].costumeExist = false;
                }
            } catch (error) {
                this.showSnackBar("Error delete file: " + error, "red");
            }
        }
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getSystemRecords()
    },
}
</script>
<style >
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.gradient-yellow-button-background {
    background: linear-gradient(0deg, rgba(255, 185, 26, 1) 1%, rgba(255, 185, 26, 0.8127626050420168) 70%);
}

#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table-settings.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 40px !important;
    text-align: center !important;
}

.table-headers-span-settings {
    font-family: 'Heebo' !important;
    font-size: 15px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows-settings {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells-settings {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table-settings tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}


</style>
    