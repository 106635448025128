<template>
    <v-dialog v-model="openMode" max-width="400px">
        <v-card style="background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255,255,255,1) 70%);">
            <v-card-title>
                <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
                <v-container>

                    <v-form @submit.prevent="submitHandler" ref="form">
                        <v-row>
                            <v-col cols="12" sm="12" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.name" outlined dense label="שם"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" style="padding-bottom: 0px !important;">
                                <v-text-field v-model="itemEdited.phone" outlined dense label="טלפון" maxlength="13"
                                    type="number" hide-spin-buttons :rules="[v => !!v || 'שדה חובה']"
                                    required></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12">
                                <v-select :items="roles" hide-details v-model="itemEdited.role" outlined dense label="הרשאות"
                                    :rules="[v => !!v || 'שדה חובה']" required></v-select>
                            </v-col>
                            <v-col cols="12" sm="12" style="padding-top: 0px;">
                                    <v-checkbox v-model="sendCall" hide-details
                                        :label="`שלח צינתוק`"></v-checkbox>
                            </v-col>
                        </v-row>
                    </v-form>
                    <v-row v-if="progressShow">
                        <v-col class="d-flex justify-center">
                            <v-progress-circular indeterminate color="#6a6aec"></v-progress-circular>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>

            <div style="display: flex; justify-content: center !important; padding-bottom: 25px;">
                <v-btn :color="'gradient-yellow-button-background'" style="margin-left: 15px; font-size: medium;"
                    @click="formTitle === 'ערוך איש קשר' ? saveEditedSubscribe() : saveNewSubscribe()">
            {{formTitle === 'ערוך איש קשר' ? "עריכה" : "הוספה"}}
                </v-btn>
                <v-btn class="gradient-button-background" @click="openMode = false"
                    style="font-size: medium;">
                    ביטול
                </v-btn>
            </div>

        </v-card>

        <v-snackbar v-model="snackbar" :timeout="4000">
            {{ snacbarText }}
            <template v-slot:action="{ attrs }">
                <v-btn :color="snackbarColorBt" text v-bind="attrs" @click="snackbar = false">
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </v-dialog>
</template>

<script>
import ApiServices from '@/services/api.service'

export default {
    props: {
        itemToEdit: Object,
        formTitle: String,
        value: { type: Boolean, default: false },

    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        itemEdited: {
            name: "",
            phone: "",
            role: "user",
        },
        sendCall: false,
        roles: ["user", "admin"]
    }),
    methods: {
        async saveNewSubscribe() {
            if (this.$refs.form.validate()) {
                const datesJSON = JSON.stringify({ item: this.itemEdited, sendCall: this.sendCall});
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/subscribes/create_subscribe";
                    const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.$emit("subscribeCreated", "", " שגיאה בהוספת איש קשר: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.itemEdited.by = "WEBSITE"
                        this.$emit("subscribeCreated", jsonObject, "איש הקשר נוסף בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved new contact: " + error, "red");
                    this.$emit("subscribeCreated", "", "Error saved new contact: " + error, "red");
                }
            }
        },
        async saveEditedSubscribe() {
            if (this.$refs.form.validate()) {
                const datesJSON = JSON.stringify({ item: this.itemEdited });
                this.progressShow = true;
                let token = localStorage.getItem("token");

                try {
                    let api = process.env.VUE_APP_BASE_URL + "/subscribes/update_subscribe";
                    const res = await fetch(api, ApiServices.requestOptions("PUT", datesJSON, token));
                    this.progressShow = false;
                    const jsonObject = await res.json();
                    if (res.status >= 400) {
                        this.$emit("subscribeEdited", "", "error: " + jsonObject, "red");
                    } else if (res.status === 200) {
                        this.$emit("subscribeEdited", this.itemEdited, "איש הקשר עודכן בהצלחה", "green");
                        this.openMode = false;
                    }
                } catch (error) {
                    this.progressShow = false;
                    this.showSnackBar("Error saved list: " + error, "red");
                    this.$emit("subscribeEdited", "", "Error edit date: " + error, "red");

                }
            }
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        if (this.formTitle === "ערוך איש קשר") {
            this.itemEdited = { ...this.itemToEdit };
            console.log(this.itemEdited);
        }
    },
}
</script>
<style scoped>
.gradient-yellow-button-background {
  background: linear-gradient(0deg, rgba(255,185,26,1) 1%, rgba(255,185,26,0.8127626050420168) 70%);
}

.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}
</style>
  