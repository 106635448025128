<template >
    <div>
        <v-row style="margin-right: 6%; display: flex; align-items: center;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
                <span style="margin-inline-start: 10px; font-weight: 600;">סך הכל {{ subscribers.length }} מספרים</span>
            </v-col>
        </v-row>

        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table1" :headers="headers" :items="subscribers" sort-by="createdAt" sort-desc :search="search"
                    :footer-props="{ 'items-per-page-options': [100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.phone }}</td>
                            <td class="table-cells">{{ item.line_number }}</td>
                            <td class="table-cells">{{ item.lastCall }}</td>
                            <td class="table-cells">{{ item.calls_count }}</td>
                        </tr>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין
                                מספרים</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>


            </div>
        </div>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import Auth from '@/services/auth.service.js'
import utils from '@/util/utils'

export default {
    components: {
        SnackBar,
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        search: "",
        subscribers: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'טלפון', align: 'start', value: 'phone' },
            { text: 'מחובר למערכת', value: 'line_number' },
            { text: 'חיוג אחרון', value: 'lastCall' },
            { text: 'כמות חיוגים', value: 'calls_count' },
        ],
    }),
    methods: {

        async getAllSubscribers() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/admin_api/get_all_subscribers`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.subscribers = jsonObject;
                    this.progressShow = false;
                    this.formatDetails();
                }
            } catch (error) {
                this.showSnackBar("Error get subscribes: " + error, "red");
            }
        },
        formatDetails() {
            this.subscribers = this.subscribers.map((subscriber) => {
                return {
                    ...subscriber,
                    lastCall: utils.getCurrentDate(subscriber.lastCall),
                }
            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openEditDialog(item, index) {
            this.editNumber = item
            this.editNumberDialog = true
        },
       
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getAllSubscribers();

    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */


#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    