<template>
    <v-dialog v-model="openMode" fullscreen hide-overlay transition="dialog-bottom-transition">
        <v-toolbar dark color="#0d2c6d">
            <v-btn icon dark @click="openMode = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>דף תשלום</v-toolbar-title>
        </v-toolbar>
        <v-card style="background-color: #F3F6F9;">
            <div style="padding: 30px;">
                <div style="padding: 30px; background-color: white;">

                    <v-row>
                        <v-col cols="12" md="6" style="border-left: 1px solid #eee;">
                            <span style="font-size: 31px; color: #0d2c6d;">רכישת חבילת {{ planToPay.name }} על סך
                                {{ planToPay.price }} ₪</span>

                            <div
                                style="background-color: #F2F2F2; height: 50px; margin-top: 40px; margin-bottom: 40px; display: flex; align-items: center; padding-right: 8px;">
                                <span style="font-size: 24px; color: #0d2c6d; font-weight: bold;">פרטי חבילה</span>
                            </div>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <span style="font-size: 20px; color: #0d2c6d; font-weight: 600;">כמות אנשי קשר</span>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <span style="font-size: 20px; color: #0d2c6d; font-weight: 600;">כמות ערוצים</span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col cols="12" md="6">
                                    <span style="font-size: 20px; color: #0d2c6d; font-weight: 600;">אחסון</span>
                                    <div>
                                        <span style="font-size: 20px; color: #0d2c6d;">{{ planToPay.storage }}GB</span>
                                    </div>
                                </v-col>
    
                            </v-row>
                        </v-col>

                        <v-col cols="12" md="6" style="border-left: 1px solid #eee;">
                            <span style="font-size: 31px; color: #0d2c6d;">תשלום מאובטח</span>
                            <div
                                style="background-color: #F2F2F2; height: 50px; margin-top: 40px; display: flex; align-items: center; padding-right: 8px;">
                                <span style="font-size: 24px; color: #0d2c6d; font-weight: bold;">פרטים אישיים</span>
                            </div>


                        </v-col>
                    </v-row>


                </div>
            </div>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </v-dialog>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'

export default {
    props: {
        planToPay: { type: Object, default: {} },
        value: { type: Boolean, default: false },

    },
    components: {
        SnackBar,
    },
    data: () => ({
        progressShow: false,
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        plans: [],
    }),

    methods: {
        actionConfirmed() {
            this.deleteItem();
        },

        async pay() {
            try {

                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/settings/get_all_plans`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.progressShow = false;
                }
            } catch (error) {
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        closeDelete() {
            this.openMode = false;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
    },
    computed: {
        openMode: {
            get() {
                return this.value
            },
            set(v) {
                return this.$emit('input', v)
            }
        },
    },
    mounted() {
        console.log(this.planToPay);
    },
}
</script>
<style scoped>
.gradient-button-background {
    background: linear-gradient(0deg, rgb(219, 218, 218) 1%, rgba(255, 255, 255, 1) 70%);
}

.box_credit_buy_package {
    box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    -webkit-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    -moz-box-shadow: -1px 3px 5px 1px rgb(240 240 240);
    border-radius: 5px;
}
</style>
      