<template >
    <div style="margin-top: 100px;">

        <v-row style="margin-right: 5%; display: flex; align-items: center; margin-bottom: 5px;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details outlined dense hide-details
                    class="search">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="6">
                <v-text-field v-model="startDate" label="הצג מתאריך" type="datetime-local" outlined dense hide-details @change="callHistory">
                    <!-- <template v-slot:prepend-inner>
                        <date-picker v-model="startDate" />
                    </template> -->
                </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="6">
                <v-text-field v-model="endDate" label="עד תאריך" type="datetime-local" outlined dense hide-details @change="callHistory">
                    <!-- <template v-slot:prepend-inner>
                        <date-picker v-model="endDate" />
                    </template> -->
                </v-text-field>
            </v-col>
        </v-row>


        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table1" :headers="headers" :items="calls" sort-by="start" sort-desc :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.callerName }}</td>
                            <td class="table-cells">{{ item.src }}</td>
                            <td class="table-cells">{{ item.line_number }}</td>
                            <td class="table-cells"><a style="text-decoration: underline;"
                                    @click="openDetailsDialog(item)">הצג פעולות</a></td>
                            <td class="table-cells">{{ item.start }}</td>
                            <td class="table-cells">{{ item.duration }}</td>
                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.last_context.context="{ item, index }">
                        <a style="text-decoration: underline;" @click="openDetailsDialog(item)">הצג פעולות</a>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">לא נמצאו שיחות</span>
                        </div>
                    </template>

                </v-data-table>


            </div>
        </div>
        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

        <ContextDetailsDialog v-model="contextDetailsDialog" v-if="contextDetailsDialog" :callDetails="callDetails" />

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import ContextDetailsDialog from '@/components/callHistory/dialogs/ContextDetailsDialog.vue'
import Auth from '@/services/auth.service.js'
import DatePicker from "@/components/widgets/DatePicker";
import Utils from "@/util/utils";

export default {
    components: {
        SnackBar,
        ContextDetailsDialog,
        DatePicker
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        callDetails: [],
        contextDetailsDialog: false,
        search: "",
        intervalId: null, // Add a property to store the interval ID
        calls: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'שם', align: 'start', value: 'callerName' },
            { text: 'מספר המתקשר', value: 'src' },
            { text: 'יעד התקשרות', value: 'line_number' },
            { text: 'שלוחה/מיקום האזנה', value: 'last_context.context' },
            { text: 'תאריך התקשרות', value: 'start' },
            { text: 'זמן שיחה', value: 'duration' },
        ],
        startDate: "",
        endDate: "",
    }),
    methods: {

        async callHistory() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                const datesJSON = JSON.stringify({ startDate: this.startDate, endDate: this.endDate });

                let api = process.env.VUE_APP_BASE_URL + `/call_history/get_history_calls`;
                const res = await fetch(api, ApiServices.requestOptions("POST", datesJSON, token));
                const jsonObject = await res.json();
                this.progressShow = false;
                if (res.status >= 400) {
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.calls = jsonObject;
                    this.formatDetails();
                }
            } catch (error) {
                this.progressShow = false;
                this.showSnackBar("Error get files: " + error, "red");
            }
        },
        formatDetails() {
            this.calls = this.calls.map((call) => {
                return {
                    ...call,
                    duration: this.formatDuration(call.duration),
                }
            });
        },

        formatDuration(seconds) {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            const sec = seconds % 60;

            const paddedHours = hours.toString().padStart(2, '0');
            const paddedMinutes = minutes.toString().padStart(2, '0');
            const paddedSeconds = sec.toString().padStart(2, '0');

            return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        openDetailsDialog(item) {
            this.callDetails = item.all_context_details;
            this.contextDetailsDialog = true;
        },
        setDates() {
            this.endDate = Utils.getFormatDate2(new Date());
            console.log(this.endDate);
            const now = new Date();
            this.startDate = Utils.getFormatDate2(new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0));
        }
    },
    watch: {
        startDate(newValue, oldValue) {
             // Code to execute when endDate changes
             console.log("endDate changed from", oldValue, "to", newValue);
           this.callHistory();
        },
        endDate(newValue, oldValue) {
            this.callHistory();
        },
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.setDates();
        this.callHistory();

    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}






/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    