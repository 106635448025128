<template >
    <div>

        <div style="display: flex; align-items: center; direction: ltr; margin-left: 6%; margin-bottom: 20px;">
            <v-btn color="#ffb91a" @click="openCreateDIalog()"
                style="margin-left: 20px; margin-right: 20px; font-weight: 500; font-size: medium;">פתח משתמש חדש</v-btn>
        </div>

        <v-row style="margin-right: 6%; display: flex; align-items: center;">
            <v-col cols="12" md="3" sm="6">
                <v-text-field v-model="search" append-icon="mdi-magnify" label="חיפוש" flat single-linehide-details
                    class="search">
                </v-text-field>
            </v-col>
            <v-col cols="12" md="3" sm="6">
                <span style="margin-inline-start: 10px; font-weight: 600;">סך הכל {{ ivrs.length }} משתמשים</span>
            </v-col>
        </v-row>


        <div style="display: flex; justify-content: center;">

            <div id="boxContainer" style="padding-left: 10px !important; padding-right: 10px !important;">


                <v-data-table id="table1" :headers="headers" :items="ivrs" sort-by="createdAt" sort-desc :search="search"
                    :footer-props="{ 'items-per-page-options': [20, 50, 100, 500, 1000], 'items-per-page-text': 'שורות בעמוד:' }">

                    <template v-if="$vuetify.breakpoint.mdAndUp" v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }">
                        <span class="table-headers-span">{{ header.text }}</span>
                    </template>

                    <template v-if="$vuetify.breakpoint.mdAndUp" #item="{ item, index }">
                        <tr class="table-rows">
                            <td class="table-cells">{{ index + 1 }}</td>
                            <td class="table-cells">{{ item.line_number }}</td>
                            <td class="table-cells">{{ item.owner_details.name }}</td>
                            <td class="table-cells">{{ item.owner_details.business_name }}</td>
                            <td class="table-cells">{{ item.email }}</td>
                            <td class="table-cells">{{ item.last_visit }}</td>
                            <td class="table-cells">
                                <div style="display: flex; justify-content: center; align-items: center;">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                            style="margin-left: 10px; cursor: pointer;" 
                                            class="audioButtons" @click="openEditDialog(item, index)">
                                    </template>
                                    <span>עריכה</span>
                                </v-tooltip>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon v-on="on" color="black" @click="switchToUser(item)">
                                            mdi-login-variant
                                        </v-icon>
                                    </template>
                                    <span>כניסה למערכת</span>
                                </v-tooltip>
                                <!-- <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <img src="@/assets/upload/icon_delete.svg" v-on="on"
                                            style="margin: 10px; cursor: pointer;" class="audioButtons"
                                            @click="openDeleteDialog(item, index)">
                                    </template>
                                    <span>מחיקה</span>
                                </v-tooltip> -->
                                </div>
                            </td>
                        </tr>
                    </template>

                    <!-- for mobile display -->
                    <template v-slot:item.actions="{ item, index }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <img src="@/assets/subscribes/icon_edit.svg" v-on="on"
                                    style="margin-bottom: 8px; margin-left: 7px; cursor: pointer;" class="audioButtons"
                                    @click="openEditDialog(item, index)">
                            </template>
                            <span>עריכה</span>
                        </v-tooltip>
                    </template>

                    <template v-slot:no-data>
                        <v-row v-if="progressShow" class="my-5">
                            <v-col class="d-flex justify-center">
                                <v-progress-circular indeterminate color="primary"></v-progress-circular>
                            </v-col>
                        </v-row>
                        <div class="ma-5">
                            <span class="text-center"
                                style="font-weight: bold; font-size: 25px; font-family: Times, serif;">אין עדיין
                                משתמשים</span>
                        </div>
                        <v-btn color="#0d2c6d" dark @click="refreshDetails()" style="margin-bottom: 10px;">
                            רענן
                        </v-btn>
                    </template>

                </v-data-table>


            </div>
        </div>

        <AddEditDialog v-model="addEditDialog" v-if="addEditDialog" :formTitle="formTitle" :itemToEdit="editedItem"
            @ivrCreated="newIvrSaved" @ivrEdited="editedIvrSaved"></AddEditDialog>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>

    </div>
</template>
    
<script>
import ApiServices from '@/services/api.service'
import SnackBar from '@/components/widgets/snackBar.vue'
import utils from '@/util/utils'
import Auth from '@/services/auth.service.js'
import AddEditDialog from '@/components/agent/dialogs/AddEditDialog'

export default {
    components: {
        SnackBar,
        AddEditDialog
    },
    data: () => ({
        snackbar: false,
        snackbarColorBt: "green",
        snacbarText: "",
        lineNumber: "",
        progressShow: false,
        addEditDialog: false,
        formTitle: "",
        itemToDelete: {},
        deleteDialog: false,
        search: "",
        ivrs: [],
        headers: [
            { text: '#', value: "index", sortable: false },
            { text: 'שם משתמש', align: 'start', value: 'line_number' },
            { text: 'שם איש קשר', value: 'owner_details.name' },
            { text: 'שם מוסד', value: 'owner_details.business_name' },
            { text: 'דוא"ל', value: 'email' },
            { text: 'התחברות אחרונה', value: 'last_visit' },
            { text: 'פעולות', value: 'actions', sortable: false },

        ],
    }),
    methods: {

        async getIvrs() {
            try {
                this.progressShow = true;
                let token = localStorage.getItem("token");
                let api = process.env.VUE_APP_BASE_URL + `/agent_api/get_ivrs_list`;
                const res = await fetch(api, ApiServices.requestOptions("GET", "", token));
                const jsonObject = await res.json();
                this.progressShow = true;
                if (res.status >= 400) {
                    this.progressShow = true;
                    this.showSnackBar("שגיאה בקבלת נתונים", "red");
                } else if (res.status === 200) {
                    this.ivrs = jsonObject;
                    console.log(this.ivrs);
                    this.progressShow = false;
                    this.formatDetails();
                }
            } catch (error) {
                this.showSnackBar("Error get subscribes: " + error, "red");
            }
        },
        formatDetails() {
            this.ivrs = this.ivrs.map((ivr) => {
                return {
                    ...ivr,
                    createdAt: utils.getCurrentDate(ivr.createdAt),
                    // lastCall: utils.getCurrentDate(subscribe.lastCall),
                }
            });
        },
        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true
        },
        // openDeleteDialog(item, index) {
        //     this.itemToDelete = item
        //     this.deleteDialog = true
        // },
        openEditDialog(item, index) {
            this.formTitle = "ערוך משתמש:"
            this.editedItem = item
            this.editedItem.itemIndex = index;
            this.addEditDialog = true
        },
        openCreateDIalog() {
            this.formTitle = "פתח משתמש חדש:"
            this.editedItem = {}
            this.addEditDialog = true
        },
        newIvrSaved(item, text, color) {

            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            this.ivrs.push(item);

        },
        editedIvrSaved(item, text, color) {
            this.showSnackBar(text, color)
            if (item === "") {
                return
            }
            const index = this.ivrs.findIndex(subscribe => subscribe._id === item._id);

            let g = Object.assign(this.ivrs[index], item);

        },
        async switchToUser(item) {

            this.progressShow = true
            let token = localStorage.getItem("token");
            localStorage.setItem('tokenAgent', token);
            let MyJSON = JSON.stringify({ _id: item._id });
            let api = process.env.VUE_APP_BASE_URL + "/agent_api/swich_to_user"

            try {
                const response = await fetch(api, ApiServices.requestOptions("POST", MyJSON, token));
                this.progressShow = false;
                const jsonObject = await response.json();
                if (response.status >= 400) {
                    this.showSnackBar("שגיאת התחברות: " + jsonObject.message, "red");
                } else if (response.status === 200) {
                    localStorage.setItem('token', jsonObject.token);
                    this.$router.replace({ name: "home" })
                }
            } catch (error) {
                this.showSnackBar("Error Login: " + error, "red");
            }
        },
        // itemDeleted(id, text, color) {
        //     if (id === "") {
        //         this.showSnackBar(text, color)
        //         return
        //     }
        //     // Use splice to remove the item at the specified index
        //     const index = this.ivrs.findIndex(subscribe => subscribe._id === id);
        //     this.ivrs.splice(index, 1);
        //     this.showSnackBar(text, color)
        // },
    },
    mounted() {
        this.lineNumber = Auth.getLineNumber();
        this.getIvrs();

    },
}
</script>
<style scoped>
body {
    height: 100vh;
    margin: 0;
}

/* ::-webkit-scrollbar {
    width: 0px !important;
} */


#boxContainer {
    width: 90%;
    margin-bottom: 50px;
}



div#customFilter input#filterText {
    display: none;
}

div.manu_f_ch {
    max-height: 30%;
    height: 20%;
    overflow: auto;
}


#table1.v-data-table .v-data-table-header th {
    background-color: rgba(0, 0, 0, 0.116) !important;
    height: 60px !important;
    text-align: center !important;
    border-left: 1px solid white !important;
}

.table-headers-span {
    font-family: 'Heebo' !important;
    font-size: 20px;
    font-weight: 600 !important;
    color: #0d2c6d;
}

.table-rows {
    height: 49px !important;
    box-shadow: -1.8369701465288538e-16px 3px 10px rgba(119, 119, 119, 0.1) !important;
    border-radius: 3px !important;
}

.table-cells {
    font-family: 'Heebo' !important;
    font-size: 17px !important;
    color: #47484b;
    border-left: 1px solid white !important;
    text-align: center !important;
}



#table1 tbody tr:nth-child(even) {
    background-color: rgba(0, 0, 0, 0.192);
    /* Replace with your desired color */
}



/* hebrew */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
    unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
    font-family: 'Heebo';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
    